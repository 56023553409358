import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function DashboardTabsPanel({ tabs }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="dashboard-tabs-panel mt-3">
      <Tabs value={value} onChange={handleChange} aria-label="tabs example" className="border-bottom">
        {tabs.map(tab => (
          <Tab key={tab.index} label={tab.label} className="dashboard-tab" />
        ))}
      </Tabs>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.index} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
}

DashboardTabsPanel.propTypes = {
  tabs: PropTypes.array.isRequired,
};
