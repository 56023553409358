/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { useGetTopExpensesQuery } from 'services/private/dashboard';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import { formatAmount } from 'utilities/helpers';
import { useNavigate } from 'react-router-dom';
import CommonMenu from 'containers/common/components/CommonMenu';
import { Box, Paper, Typography } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import { durationOptions } from '../utilis/data';
import useGetGraphData from '../customHooks/useGetGraphData';
import useGetChartColorsArray from '../customHooks/useGetChartColorsArray';

function TopExpenses() {
  const navigate = useNavigate();
  const { company } = useAuthInfo();
  const [duration, setDuration] = useState('this fiscal year');

  const { data: { data: topExpensesData = [] } = {}, isLoading } = useGetTopExpensesQuery(
    { duration, company: company?.id },
    { skip: !duration }
  );

  const chartColors = useGetChartColorsArray();

  const { graphData } = useGetGraphData(topExpensesData, duration);

  const handleClick = useCallback(accountName => {
    navigate({
      pathname: '/reports/expensesByCategory/details',
      search: `?duration=custom&start_date=${topExpensesData.start_date}&end_date=${topExpensesData.end_date}&category_name=${accountName}`,
    });
  }, []);

  const handleMenuItemClicked = useCallback(item => {
    setDuration(item.value);
  }, []);

  return (
    <Paper className="py-3 px-4">
      <Box className="pb-1 d-flex justify-content-between align-items-center">
        <Typography variant="h4">Your Expenses</Typography>
        <CommonMenu
          menus={durationOptions}
          selectedOption={duration}
          func={handleMenuItemClicked}
        />
      </Box>
      <Box sx={{ height: '100%' }}>
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <SectionLoader />
          ) : graphData.length > 0 ? (
            <ResponsiveContainer sx={{ height: '100%' }} height={297}>
              <PieChart width={200} height={200}>
                <Pie
                  data={graphData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                >
                  {graphData.map((entry, index) => (
                    <Cell
                      key={entry.name}
                      fill={chartColors[index % chartColors.length]}
                      onClick={() => handleClick(entry.accountName)}
                      className="cursor-pointer"
                    />
                  ))}
                </Pie>
                <Tooltip formatter={value => `${formatAmount(value)}`} />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  content={({ payload }) => (
                    <ul
                      style={{
                        listStyle: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {payload.map((entry, index) => (
                        <li
                          key={`item-${index}`}
                          style={{
                            fontSize: '14px',
                            margin: '0 10px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ color: entry.color }}>{entry.value}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Box sx={{ height: 297 }} className="d-flex justify-content-center align-items-center">
              <Typography variant="h5" className="clr-add">No expense recorded for {duration}</Typography>
            </Box>
          )
        }
      </Box>
    </Paper>
  );
}

export default TopExpenses;
