import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, Divider, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Email, LocalPhone } from '@mui/icons-material';

import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import AttendanceBar from 'containers/pages/attendance/components/AttendanceBar';
// import AnnoucmentSlider from 'containers/common/components/Announcements';
import Ava from 'assets/img/avatar.png';
import { largeTextSlice } from 'utilities/helpers';
import useAuthInfo from 'customHooks/useAuthInfo';
import DashBoardCalendar from '../DashboardCalender';

function ActionIcon(value, url, icon) {
  return (
    value ? (
      <a
        href={url}
        className="p-1 rounded-circle d-flex justify-content-center align-items-center"
        style={{ background: 'rgb(128 128 128 / 25%)', width: '30px', height: '30px' }}
      >
        {icon}
      </a>
    ) : (
      <span
        className="p-1 rounded-circle d-flex justify-content-center align-items-center"
        style={{ background: 'rgb(128 128 128 / 25%)', width: '30px', height: '30px' }}
      >
        {icon}
      </span>
    )
  );
}

function RenderEmployeeDetail(label, value,) {
  return (
    value && (
      <Box className="w-100 d-flex justify-content-between">
        <Typography variant="body2" className="fw-bold">{label}</Typography>
        <Typography variant="body2">{value}</Typography>
      </Box>
    )
  );
}

function OverviewTab() {
  const { palette } = useTheme();

  const greyColor = useMemo(() => palette.greyColor.contrastText, []);

  const user = useSelector(state => state.auth.user);

  const { company } = useAuthInfo();

  return (
    <>
      <MetaTagsHelmet moduleName="Dashboard" />

      <Box className="mb-3" style={{ marginTop: '-10px' }}>
        <AttendanceBar />
      </Box>
      <Grid spacing={2} container className=" mb-2">
        <Grid xl={4} lg={4} md={12} sm={12} item>
          <Paper className="p-4 d-flex justify-content-center flex-column align-items-center">
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Avatar src={(user?.profile?.photo || user?.profile?.profile_pic) || Ava} className="mb-2" sx={{ width: 200, height: 200, border: '1px solid black' }} />
              <Typography variant="h3" className="fw-bold">{largeTextSlice(user?.profile?.employee_name, 15)}</Typography>
              <Typography variant="h4" sx={{ color: greyColor }}>{largeTextSlice(user?.profile?.job_title, 15)}</Typography>

              <Stack direction="row" justifyContent="center" spacing={1}>
                {ActionIcon(user?.profile?.company_mob, `tel:${user?.profile?.company_mob}`, <LocalPhone style={{ fontSize: '18px' }} />)}
                {ActionIcon(user?.profile?.company_email, `mailto:${user?.profile?.company_email}`, <Email style={{ fontSize: '18px' }} />)}
              </Stack>

            </Stack>
            <Divider />
            <Box className="pt-3 w-100 border-top mt-3">
              {RenderEmployeeDetail('Department', user?.profile?.department?.toUpperCase() || 'N/A')}
              <br />
              {RenderEmployeeDetail('Company', company?.company_name || 'N/A')}
              <br />
              {RenderEmployeeDetail('Nationality', user?.profile?.nationality || 'N/A')}
              <br />
              {RenderEmployeeDetail('Gender', user?.profile?.gender || 'N/A')}
            </Box>
          </Paper>
        </Grid>
        <Grid xl={8} lg={8} md={12} sm={12} item>
          <Paper className="p-4" sx={{ minHeight: '100%' }}>
            <Typography variant="body1" className="fw-bold">
              Meetings & Activity
            </Typography>
            <Box className="dashboard-calendar-meeting">
              <DashBoardCalendar />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default OverviewTab;
