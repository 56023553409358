import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, AvatarGroup, Grid, Paper, Typography } from '@mui/material';
import { STORAGE_URL } from 'utilities/constants';
import defaultImg from 'assets/img/avatar.png';

function MeetingCard({ meeting }) {
  const navigate = useNavigate();
  return (
    <Paper className="px-2 py-1 my-2" onClick={() => navigate('/internal-meetings/')}>
      <Grid spacing={2} container>
        <Grid xl={3} lg={3} md={3} sm={3} item className="d-flex justify-content-start">
          <AvatarGroup max={2}>
            {meeting.participants && meeting.participants.length > 0 ? (
              meeting.participants.map(user => (
                <Avatar
                  alt="Participant"
                  src={`${STORAGE_URL}${user.photo}`}
                  key={user.photo}
                />
              ))
            ) : (
              <>
                <Avatar alt="Participant" src={defaultImg} />
                <Avatar alt="Participant" src={defaultImg} />
              </>
            )}
          </AvatarGroup>
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={6} item className="d-flex align-items-center">
          <Typography variant="h5" className="fw-bold">{meeting?.title}</Typography>
        </Grid>
        <Grid xl={3} lg={3} md={3} sm={3} item>
          <Typography variant="body1" className="fw-bold"> {meeting.from_time
            ? moment(meeting.from_time, 'hh:mm:ss').format('hh:mm A')
            : ''}
          </Typography>
          <Typography variant="smallText"> {meeting.meeting_date && meeting.from_time
            ? moment(`${meeting.meeting_date} ${meeting.from_time}`).fromNow()
            : ''}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

MeetingCard.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default MeetingCard;
