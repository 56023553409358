import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function Clock() {
  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <Typography fontSize={20} fontWeight={600} color="primary">
      {date.toLocaleTimeString()}
    </Typography>
  );
}

export default Clock;
