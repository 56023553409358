/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
// import SearchIcon from 'assets/svg/search-icon.svg';
import CustomDrawer from './CustomDrawer';

function SearchPopup({ children, isTopbarSelect }) {
  const [isDrawerOpen, setDrawerState] = useState(false);

  const toggleDrawer = () => setDrawerState(prevState => !prevState);

  return (
    <>
      <Box>
        <Tooltip label="Search">
          {
            isTopbarSelect ? (
              <IconButton className="svg-icon-btn" onClick={toggleDrawer}>
                {/* <img src={SearchIcon} alt="Search" height={18} width={18} /> */}
                <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.8311 14.0156L11.2242 10.4095C12.2696 9.15436 12.7909 7.54453 12.6797 5.91486C12.5684 4.2852 11.8331 2.76116 10.6268 1.65979C9.42052 0.558426 7.83603 -0.0354732 6.20299 0.0016403C4.56995 0.0387538 3.01409 0.704023 1.85906 1.85906C0.704023 3.01409 0.0387538 4.56995 0.0016403 6.20299C-0.0354732 7.83603 0.558426 9.42052 1.65979 10.6268C2.76116 11.8331 4.2852 12.5684 5.91486 12.6797C7.54453 12.7909 9.15436 12.2696 10.4095 11.2242L14.0156 14.8311C14.0692 14.8847 14.1328 14.9271 14.2027 14.9561C14.2727 14.9851 14.3477 15 14.4234 15C14.4991 15 14.5741 14.9851 14.644 14.9561C14.714 14.9271 14.7776 14.8847 14.8311 14.8311C14.8847 14.7776 14.9271 14.714 14.9561 14.644C14.9851 14.5741 15 14.4991 15 14.4234C15 14.3477 14.9851 14.2727 14.9561 14.2027C14.9271 14.1328 14.8847 14.0692 14.8311 14.0156ZM1.16852 6.3552C1.16852 5.32937 1.47271 4.32658 2.04263 3.47364C2.61255 2.62069 3.4226 1.9559 4.37035 1.56333C5.31809 1.17076 6.36096 1.06805 7.36708 1.26818C8.37319 1.46831 9.29737 1.96229 10.0227 2.68766C10.7481 3.41303 11.2421 4.33721 11.4422 5.34333C11.6424 6.34945 11.5396 7.39232 11.1471 8.34006C10.7545 9.2878 10.0897 10.0979 9.23677 10.6678C8.38383 11.2377 7.38103 11.5419 6.3552 11.5419C4.98008 11.5404 3.66171 10.9934 2.68935 10.0211C1.71699 9.0487 1.17004 7.73033 1.16852 6.3552Z" className="svg-icon" />
                </svg>

              </IconButton>
            )
              : (
                <Button onClick={toggleDrawer} size="small" color="primary" variant="contained">
                  <Search />
                </Button>
              )
          }
        </Tooltip>
      </Box>
      <CustomDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        header={<h4>Personalized Search</h4>}
      >
        <div className="theme-light px-2" style={{ width: 380 }}>
          {children(toggleDrawer)}
        </div>
      </CustomDrawer>
    </>
  );
}

SearchPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isTopbarSelect: PropTypes.bool,
};

SearchPopup.defaultProps = {
  isTopbarSelect: false
};

export default SearchPopup;
