import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import propTypes from 'prop-types';
import { dailyReportsTableHeadData } from '../utilities/data';

function DailyReportsTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {dailyReportsTableHeadData.map(row => (
          <TableCell
            key={row.id}
            align="left"
            className="fw-semibold"
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DailyReportsTableHead.propTypes = {
  order: propTypes.string.isRequired,
  orderBy: propTypes.string.isRequired,
};

export default DailyReportsTableHead;
