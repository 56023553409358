import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Grid, Paper, Stack, Typography } from '@mui/material';
import { AVATAR } from 'utilities/constants';

function UserCard({ user }) {
  return (
    <Grid xl={4} lg={4} md={4} sm={6} className="pl-1">
      <Link to={`/hr/employees/${user.id}/detail`}>
        <Paper className="p-4 d-flex flex-column justify-content-center align-items-center" sx={{ height: '100%' }}>
          <Avatar
            src={(user?.profile?.photo || user?.profile?.profile_pic) || AVATAR}
            alt="user"
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              borderRadius: '50%',
              marginBottom: '1rem',
            }}
          />
          <Stack spacing={1}>
            <Typography variant="h4" sx={{ textAlign: 'center' }} className="fw-bold">{user && user.profile && user.profile.employee_name}</Typography>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>{user && user.profile && user.profile.job_title}</Typography>
          </Stack>
        </Paper>
      </Link>
    </Grid>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
