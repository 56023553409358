export const fieldStyles = primaryColor => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: '35px',
    // height: '35px',
    fontSize: '12px',
    border: state.isFocused
      ? `1.8px solid ${primaryColor} !important`
      : `1px solid ${provided.borderColor}`,
    '&:hover': {
      borderColor: state.isFocused
        ? `1.8px solid ${primaryColor} !important`
        : `1px solid ${provided.borderColor}`,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? primaryColor : provided.backgroundColor,
    color: state.isSelected ? '#fff' : provided.color,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: state.isSelected ? primaryColor : provided.backgroundColor,
      color: state.isSelected ? '#fff' : provided.color,
    },
  }),
});

export const selectWrapperStyles = {};

export const test = '';
