import { privateAPI } from './index';

export const attendanceAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getForgetCheckOut: build.query({
      query: params => ({
        url: 'attendance/check-forget-to-check-out/',
        method: 'GET',
        params
      }),
      providesTags: ['GetForgetCheckOut'],
    }),

    getAttendanceCheckIn: build.query({
      query: params => ({
        url: 'attendance/attendance-for-employee/',
        method: 'GET',
        params
      }),
      providesTags: ['GetAttendanceCheckIn'],
    }),

    getAttendanceReports: build.query({
      query: body => ({
        url: 'attendance/attendance-for-employee/',
        method: 'GET',
        params: {
          attendance_id: body.attendanceId,
        },
      }),
      providesTags: ['GetAttendanceReports'],
    }),

    updateCheckinStatus: build.mutation({
      query: body => ({
        url: 'attendance/employee-attendance/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceStatus',
        'GetAttendanceChartData',
      ],
    }),

    updateForgetCheckinStatus: build.mutation({
      query: body => ({
        url: 'attendance/forget-to-check-in/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceStatus',
        'GetAttendanceChartData',
      ],
    }),

    updateCheckInTime: build.mutation({
      query: body => ({
        url: `attendance/update-check-in-time/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceStatus',
        'GetAttendanceChartData',
      ],
    }),

    updateCheckOut: build.mutation({
      query: body => ({
        url: `attendance/employee-attendance/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    getAttendanceFacts: build.query({
      query: body => ({
        url: 'attendance/attendance-count/',
        method: 'GET',
        params: {
          profile_id: body.profile_id,
          start_date: body.start_date,
          end_date: body.end_date,
        },
      }),
      providesTags: ['GetAttendanceFacts'],
    }),

    updateStartBreakTime: build.mutation({
      query: body => ({
        url: 'attendance/break-time/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetAttendanceCheckIn'],
    }),

    updateEndBreakTime: build.mutation({
      query: body => ({
        url: `attendance/break-time/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetAttendanceCheckIn'],
    }),

    updateBreakTimeManually: build.mutation({
      query: body => ({
        url: `attendance/break-time/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetAttendanceData',
      ],
    }),

    getAttendanceData: build.query({
      query: params => ({
        url: 'attendance/employee-attendance/',
        method: 'GET',
        params,
      }),
      providesTags: ['GetAttendanceData'],
    }),

    getEmployeeAttendanceStatus: build.query({
      query: params => ({
        url: 'attendance/absent-employee/',
        method: 'GET',
        params,
      }),
      providesTags: ['GetEmployeeAttendanceStatus'],
    }),

    getAttendanceKpiData: build.query({
      query: params => ({
        url: 'attendance/employee-attendance/',
        method: 'GET',
        params: {
          ...params,
          kpi: true,
        },
      }),
      providesTags: ['GetAttendanceKpiData'],
    }),

    getProductivityData: build.query({
      query: params => ({
        url: 'attendance/employee-attendance/',
        method: 'GET',
        params
      }),
      providesTags: ['GetAttendanceData'],
    }),

    updateForgetToCheckOutAttendance: build.mutation({
      query: body => ({
        url: `attendance/forget-to-check-out/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceDates',
        'GetForgetCheckOut',
        'GetAttendanceReports',
      ],
    }),

    getAttendanceDates: build.query({
      query: () => 'attendance/attendance-dates/',
      providesTags: ['GetAttendanceDates'],
    }),

    getAttendanceStatus: build.query({
      query: body => ({
        url: 'attendance/attendance-pie-chart/',
        method: 'GET',
        params: {
          ...body,
        },
      }),
      providesTags: ['GetAttendanceStatus'],
    }),

    getAttendanceChartData: build.query({
      query: body => ({
        url: 'attendance/attendance-bar-chart/',
        method: 'GET',
        params: {
          ...body,
        },
      }),
      providesTags: ['GetAttendanceChartData'],
    }),

  }),
});

export const {
  useGetForgetCheckOutQuery,
  useUpdateCheckinStatusMutation,
  useUpdateForgetCheckinStatusMutation,
  useUpdateCheckInTimeMutation,
  useUpdateCheckOutMutation,
  useGetAttendanceCheckInQuery,
  useGetAttendanceReportsQuery,
  useGetAttendanceFactsQuery,
  useUpdateStartBreakTimeMutation,
  useUpdateEndBreakTimeMutation,
  useUpdateBreakTimeManuallyMutation,
  useGetAttendanceDataQuery,
  useGetEmployeeAttendanceStatusQuery,
  useUpdateForgetToCheckOutAttendanceMutation,
  useGetProductivityDataQuery,
  useGetAttendanceKpiDataQuery,
  useGetAttendanceDatesQuery,
  useGetAttendanceStatusQuery,
  useGetAttendanceChartDataQuery,
} = attendanceAPI;
