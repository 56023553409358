import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  saleSuppliersAccountState: {},
};

const salesSuppliersAccountSlice = createSlice({
  name: 'salesAccounts',
  initialState,
  reducers: {
    getSalesSuppliersAccountStatementState: (state, action) => ({
      ...state,
      saleSuppliersAccountState: action.payload,
    }),
  },
});

export const { getSalesSuppliersAccountStatementState } = salesSuppliersAccountSlice.actions;
export default salesSuppliersAccountSlice.reducer;
