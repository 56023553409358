import { borderGrey } from '../themeVariables';

export const topbarStyling = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  height: '60px',
  zIndex: '101',
  boxShadow: '0 2px 15px 0 rgba(0,0,0,0.2)',
  borderRadius: '10px'
};

export const topbarLeftStyling = {
  position: 'absolute',
  left: '0',
  display: 'flex',
  height: '100%',
  width: '50%',
};

export const topbarMiddleStyling = {
  position: 'absolute',
  left: '28%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
};

export const topbarRightStyling = {
  position: 'relative',
  right: '0',
  display: 'flex',
  height: '100%',
  // marginRight: '15px',
};

export const topbarCompanyStyling = {
  position: 'absolute',
  right: '0',
  display: 'flex',
  marginRight: '163px',
};
export const megamenuStyling = {
  position: 'absolute',
  top: '4rem',
  background: 'white',
  padding: '10px',
  width: '600px',
  zIndex: '2',
  border: `1px solid ${borderGrey}`,
};

export const backdropStyling = {
  background: 'transparent',
  zIndex: '1',
};

export const searchBarStyling = {
  display: 'flex',
  alignItems: 'center',
  // background: '#f2f4f7',
  background: 'white',
  border: '1px solid #f2f4f7',
  boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
  padding: '0px 10px',
  borderRadius: '20px',
};

export const test = 'test';
