import {
  Analytics,
  // Assessment,
  ManageHistory,
  Toc,
  WorkHistory,
} from '@mui/icons-material';
import { palette } from 'styles/mui/theme';

const {
  primary: { main },
} = palette;

export const allTagsStatuses = {
  early: 'Early',
  late: 'Late',
  on_time: 'On Time',
};

export const colors = [
  main,
  '#979595',
  '#525151',
  '#c5c5c5',
  '#008ee0',
  '#47bcff',
  '#85d2ff',
  '#a2aaa8',
  '#7c8282',
  '#6c6968',
];

export const attendanceStatusChartLabelColorMap = {
  early: { label: 'Early', color: main },
  late: { label: 'Late', color: '#B4B4B4' },
  on_time: { label: 'On Time', color: '#3386BD' },
};

export const countKeyMappings = {
  early: 'early',
  late: 'late',
  on_time: 'on_time',
};

export const attendanceChartLegendLabelsMap = {
  present: 'Presents',
  absent: 'Absents',
};

export const reportsCardsList = [
  // {
  //   path: 'daily-tasks-report',
  //   label: 'Submit Report',
  //   icon: Assessment,
  // },
  {
    label: 'Productivity Report',
    path: 'productivity-report',
    icon: WorkHistory,
  },
  {
    label: 'Time Summary',
    path: 'time-summary',
    icon: ManageHistory,
  },
  {
    label: 'Employee Attendance Tracking',
    icon: Analytics,
    path: 'employee-attendance',
  },
  {
    label: 'Report Request',
    icon: Toc,
    path: 'report-request',
  },
];

export const timeSummaryTableHeadings = [
  {
    id: 'employee_name',
    label: 'Employee Name',
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'office_start',
    label: 'Office Start',
  },
  {
    id: 'checked_in',
    label: 'Checked-in Time',
  },
  {
    id: 'checked_out',
    label: 'Checked-out Time',
  },
  {
    id: 'present_hours',
    label: 'Present Hours',
  },
  {
    id: 'productive_hours',
    label: 'Productive Hours',
  },
  {
    id: 'break_time',
    label: 'Break Time',
  },
  {
    id: 'idle_time',
    label: 'Idle Time',
  },
  {
    id: 'overtime',
    label: 'Overtime(hrs)',
  },
  {
    id: 'less_time',
    label: 'Less Time(hrs)',
  },
  {
    id: 'forget_to_check_in_reason',
    label: 'Reason',
  },
];

export const employeeAttendanceTableHeadings = [
  {
    id: 'employee_name',
    label: 'Employee Name',
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export const productivityReportTableHeadings = [
  {
    id: 'date',
    label: 'Date',
    span: 1
  },
  {
    id: 'project_name',
    label: 'Projects/Tasks',
    span: 2
  },
  {
    id: 'task_details',
    label: 'Task Details',
    span: 3
  },
  {
    id: 'reason',
    label: 'Report Request Reasons',
    span: 2
  },
  {
    id: 'time_spent',
    label: 'Time Spent',
    span: 2
  },
  {
    id: 'actions',
    label: 'Actions',
    span: 1
  },
];

export const reportRequestHeadings = [
  {
    id: 'employee_name',
    label: 'Employee Name',
  },
  {
    id: 'attendance_date',
    label: 'Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'reason',
    label: 'Reason',
    renderColumn: row => `${row?.reason?.slice(0, 25)}...`,
  },
  {
    id: 'actions',
    label: 'Actions',
  }
];
