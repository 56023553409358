export const borderGrey = '#dddddd';
export const muted = '#999999';
export const colorGrey = '#dddddd';
export const greyBg = '#f0f0f0da';
export const darkGrey = '#4a4a4a';
export const linkBlue = '#4ca3ff';
export const incomeColor = '#00c49f';
export const expenseColor = '#f2d972';
export const activeColor = '#E0FFC8';
export const inActiveColor = '#FFD4D4';
export const greyColor = '#e7e7e7';
export const greyText = '#858585';
export const chartsColors = ['#022a40', '#a9a9a9'];
