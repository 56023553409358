import { privateAPI } from './index';

export const recentActivityAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    recentActivity: build.query({
      query: params => ({
        url: 'user/recent/activity/',
        method: 'GET',
        params
      }),
      providesTags: ['recentActivity'],
    }),
    addRecentActivity: build.mutation({
      query: body => ({
        url: 'user/recent/activity/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['recentActivity'],
    }),
    addLog: build.mutation({
      query: body => ({
        url: 'accounting/ActivityLog/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['recentActivity'],
    }),
    addSupplierRecentActivity: build.mutation({
      query: body => ({
        url: 'accounting/purchase/supplier/SupplierActivityLog/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['recentActivity'],
    }),
    addCustomerRecentActivity: build.mutation({
      query: body => ({
        url: 'accounting/sales/customer/ActivityLog/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['recentActivity'],
    }),
  }),
});

export const { useRecentActivityQuery, useAddLogMutation, useAddRecentActivityMutation, useAddSupplierRecentActivityMutation, useAddCustomerRecentActivityMutation } =
  recentActivityAPI;
