import { Box, Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormikDatePicker,
  FormikField,
  FormikSelect,
  GroupedOptionsFormikSelect,
} from 'shared/form';
import { changeQueryParameters } from 'utilities/helpers';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SearchForm({ toggle, resetValues, initValues, modifiedData, searchFormValSchema, isTopbarSelect }) {
  const nevigate = useNavigate();
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={searchFormValSchema}
      onSubmit={(values, { resetForm }) => {
        nevigate({
          pathname: isTopbarSelect ? values?.module : pathname,
          search: isTopbarSelect ? `query=${values?.query}` : changeQueryParameters(query, values),
        });

        if (!isTopbarSelect) {
          resetForm(initValues);
        }

        toggle();
      }}
    >
      {({ isSubmitting, touched, setFieldValue, setFieldTouched, setValues }) => (
        <Form className="p-3">
          <Grid container alignItems="center" mb={4} columnSpacing={2} rowGap={3}>

            {modifiedData?.map(item => (
              <Grid key={item.label} item xs={12} md={item.class} lg={item.class}>
                {(item?.type === 'text' || item?.type === 'number') && (
                  <Grid className="w-100">
                    <FormikField
                      labelMarginBottom="-2px"
                      label={item.label}
                      disabled={item.disabled ?? false}
                      placeholder={item.placeholder}
                      name={item.fieldName}
                      variant="outlined"
                      type={item.type || 'text'}
                    />
                  </Grid>
                )}
                {item?.type === 'date' && (
                  <Grid className="w-100">
                    <FormikDatePicker
                      label={item.label}
                      name={item.fieldName}
                      placeholder={item.placeholder}
                      //   onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      //   touched={touched.payment_date}
                      //   error={errors.payment_date}
                    />
                  </Grid>
                )}
                {item?.type === 'list' && (
                  <Grid className="w-100">
                    <FormikSelect
                      label={item.label}
                      name={item.fieldName}
                      value={item.value}
                      type="text"
                      placeholder={item.placeholder}
                      options={item.options}
                    />
                  </Grid>
                )}
                {item?.type === 'groupList' && (
                  <Grid className="w-100">
                    <GroupedOptionsFormikSelect
                      label={item.label}
                      name={item.fieldName}
                      type="text"
                      value={item.value}
                      placeholder={item.placeholder}
                      options={item.options}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
          <Box className="d-flex gap-2">
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={isSubmitting}
              className="icon-btn"
            >
              Search
            </Button>

            <Button
              variant="mutedColor"
              size="small"
              type="submit"
              onClick={() => setValues(resetValues)}
              className="icon-btn"
              disabled={!touched || isSubmitting}
            >
              Reset
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
SearchForm.propTypes = {
  searchFormValSchema: PropTypes.any,
  resetValues: PropTypes.any.isRequired,
  initValues: PropTypes.any.isRequired,
  modifiedData: PropTypes.any.isRequired,
  toggle: PropTypes.func.isRequired,
  isTopbarSelect: PropTypes.bool,
};
SearchForm.defaultProps = {
  searchFormValSchema: null,
  isTopbarSelect: false
};
