import React, { useEffect, useMemo, useState } from 'react';
import propTypes, { array } from 'prop-types';
import moment from 'moment';
import { Coffee } from '@mui/icons-material';
import CommonAttendanceCard from './CommonAttendanceCard';

function BreakCard({ breakData, checkInData, status }) {
  const [startTime, setStartTime] = useState(null);
  // const [isCustom, setIsCustom] = useState(false);
  const [timeDiff, setTimeDiff] = useState(moment.duration(0));

  const modifiedTime = useMemo(() => {
    const initialValue = moment.duration(0, 'seconds');
    if (breakData?.length > 1) {
      const durations = breakData.map(data => {
        const start = moment(data?.start_break_time, 'HH:mm:ss');
        const end = moment(data?.end_break_time, 'HH:mm:ss');
        // if (data?.is_custom && !checkInData?.is_check_out) {
        //   setIsCustom(true);
        // }
        return moment.duration(end.diff(start));
      });
      const elapsedTime = durations.reduce(
        (accumulator, currentValue) => accumulator.add(currentValue),
        initialValue
      );
      return elapsedTime;
    }
    if (
      breakData?.length === 1 &&
      breakData[0]?.start_break_time &&
      !breakData[0]?.end_break_time
    ) {
      const initialStartTime = moment(breakData[0]?.start_break_time, 'HH:mm:ss');
      const initialEndTime = moment();
      return moment.duration(initialEndTime.diff(initialStartTime));
    }
    if (
      breakData?.length === 1 &&
      breakData[0]?.start_break_time &&
      breakData[0]?.end_break_time
    ) {
      const firstStart = moment(breakData[0]?.start_break_time, 'HH:mm:ss');
      const firstEnd = moment(breakData[0]?.end_break_time, 'HH:mm:ss');
      return moment.duration(firstEnd.diff(firstStart));
    }
    return initialValue;
  }, [breakData, breakData[0]?.start_break_time, breakData[0]?.end_break_time]);

  const modifiedDuration = useMemo(() => {
    const initialStartTimeValue = moment.duration(0, 'seconds');
    if (
      breakData?.length > 0 &&
      !breakData[(breakData?.length ?? 1) - 1]?.end_break_time &&
      breakData[(breakData?.length ?? 1) - 1]?.start_break_time
    ) {
      const newStartTime = moment(
        breakData[(breakData?.length ?? 1) - 1]?.start_break_time,
        'HH:mm:ss'
      );
      const newCurrentTime = moment();
      const newDuration = moment.duration(newCurrentTime.diff(newStartTime));
      return newDuration;
    }
    return initialStartTimeValue;
  }, [breakData, breakData[(breakData?.length ?? 1) - 1]?.start_break_time]);

  useEffect(() => {
    if (modifiedTime && breakData?.length > 1) {
      setStartTime(modifiedTime.add(modifiedDuration));
    } else {
      setStartTime(modifiedTime);
    }
  }, [modifiedTime, breakData?.length]);

  function refreshClock() {
    setTimeDiff(prevDuration => moment.duration(prevDuration).add(1, 'seconds'));
  }

  useEffect(() => {
    let timerId;
    if (startTime) {
      setTimeDiff(startTime);
    }
    if (startTime && status) {
      timerId = setInterval(refreshClock, 1000);
    }
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [startTime]);
  return (
    <CommonAttendanceCard
      title="Break Time"
      icon={<Coffee color="primary" />}
      data={
        timeDiff && checkInData && startTime && !checkInData?.is_check_out
          ? `${timeDiff.hours()}h  ${timeDiff.minutes()}m`
          : '0h  0m'
      }
    />
  );
}

BreakCard.propTypes = {
  breakData: array,
  checkInData: propTypes.object,
  status: propTypes.bool,
  // breakForm: propTypes.func,
};

BreakCard.defaultProps = {
  breakData: [],
  checkInData: {},
  status: false,
  // breakForm: () => null,
};

export default BreakCard;
