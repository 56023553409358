import { useMemo } from 'react';
import { formatAmount, getSorting } from 'utilities/helpers';

function useGetGraphData(apiData, duration, type) {
  let graphData = [];

  if (type === 'incomeExpense') {
    graphData = useMemo(
      () => (apiData
        ? apiData?.month_year_list?.map(item => ({
          month: `${item.month} ${item.year}`,
          Income: item.income,
          Expense: item.expense,
        }))
        : []),
      [duration, apiData]
    );
    return { graphData };
  }
  graphData = useMemo(
    () => [...apiData]
      .sort(getSorting('asc', 'percentage'))
      .slice(0, 5)
      .filter(item => item.amount > 0 && formatAmount(item.percentage) !== '0.00')
      .map(item => ({
        accountName: item.chart_of_account,
        name: `${item.chart_of_account} (${formatAmount(item.percentage)}%)`,
        value: item.amount,
      })),
    [duration, apiData]
  );
  return { graphData };
}

export default useGetGraphData;
