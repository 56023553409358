/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  Add,
  Cancel,
  Menu as MenuIcon,
  ReceiptLong,
  ShoppingCart,
} from '@mui/icons-material';

import useAuthInfo from 'customHooks/useAuthInfo';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function QuickCreateMenu() {
  const [isOpen, setIsOpen] = useState(null);

  const primaryColor = useGetThemeColor();

  const { allowedSections, allowedModules } = useAuthInfo();
  const isSalesSection = allowedModules?.find(module => (module?.section === 'Sales'));
  const isPurchaseSection = allowedModules?.find(module => (module?.section === 'Purchase'));

  const handleToggleMenu = useCallback(e => {
    setIsOpen(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsOpen(null);
  }, []);
  return (
    <Box className="position-relative">
      {(isSalesSection || isPurchaseSection) && (
      <Tooltip title="Quick Create" position="bottom">
        <IconButton color="primary" className="svg-icon-btn" onClick={handleToggleMenu}>
          {isOpen ? <Cancel />
            :
            (
              <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_501_99)">
                  <path d="M7.5 15C3.3625 15 0 11.6375 0 7.5C0 3.3625 3.3625 0 7.5 0C11.6375 0 15 3.3625 15 7.5C15 11.6375 11.6375 15 7.5 15ZM7.5 1.25C4.05625 1.25 1.25 4.05625 1.25 7.5C1.25 10.9438 4.05625 13.75 7.5 13.75C10.9438 13.75 13.75 10.9438 13.75 7.5C13.75 4.05625 10.9438 1.25 7.5 1.25ZM7.5 11.875C7.39375 11.875 7.2875 11.85 7.1875 11.7938C6.8875 11.625 6.78125 11.2375 6.95625 10.9438L8.56875 8.13125H6.0125C5.69375 8.13125 5.39375 7.98125 5.2 7.725C5.00625 7.46875 4.95 7.1375 5.04375 6.83125C5.05625 6.7875 6.95625 3.45 6.95625 3.45C7.125 3.15 7.50625 3.04375 7.80625 3.21875C8.10625 3.3875 8.2125 3.76875 8.0375 4.06875L6.43125 6.88125H8.99375C9.3625 6.88125 9.7 7.08125 9.875 7.4125C10.05 7.74375 10.0312 8.13125 9.825 8.4375L8.0375 11.5688C7.925 11.7688 7.7125 11.8813 7.49375 11.8813L7.5 11.875ZM8.76875 7.775C8.76875 7.775 8.76875 7.78125 8.7625 7.7875V7.775H8.76875Z" className="svg-icon" />
                </g>
                <defs>
                  <clipPath id="clip0_501_99">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            )}
        </IconButton>
      </Tooltip>
      )}
      <Menu
        key={isOpen}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={handleCloseMenu}
        sx={{ top: 50, }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box
          className={`${(allowedSections?.item_master || allowedSections?.journal_voucher || allowedSections?.bank_master) ? 'w-700' : ''} topbar-megamenu`}
          sx={{

            '& li:hover': {
              backgroundColor: primaryColor,
            },
          }}

        >
          <Box className="container px-0">
            <Box className="row m-0 p-4">
              {(allowedSections?.item_master || allowedSections?.journal_voucher || allowedSections?.bank_master) && (
                <Box className="col-md-4">
                  <Box className="d-flex align-items-center gap-1">
                    <MenuIcon />
                    <Typography variant="h5">GENERAL</Typography>
                  </Box>
                  <Box className="flex-column mt-1">
                    {(allowedSections?.item_master?.all || allowedSections?.item_master?.create) && (
                    <Link onClick={handleCloseMenu} to="/accounting/items/add">
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Item Master</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.journal_voucher?.all || allowedSections?.journal_voucher?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/accountant/journals/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Journal Voucher</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.bank_master?.all || allowedSections?.bank_master?.create) && (

                    <Link onClick={handleCloseMenu} to="/accounting/banking/add">
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Bank Master</Typography>
                      </MenuItem>
                    </Link>
                    )}
                  </Box>
                </Box>
              )}
              {isSalesSection && (
                <Box className={`col-md-${!isSalesSection ? 12 : 4}`}>
                  <Box className="d-flex align-items-center gap-1">
                    <ReceiptLong />
                    <Typography variant="h5" className="quick-create-menu-header">
                      SALES
                    </Typography>
                  </Box>
                  <Box className="flex-column mt-1">
                    {(allowedSections?.account_master?.all || allowedSections?.account_master?.create) && (

                    <Link onClick={handleCloseMenu} to="/accounting/sales/accounts/add">
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Account Master</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.customer?.all || allowedSections?.customer?.create) && (

                    <Link onClick={handleCloseMenu} to="/accounting/sales/customers/add">
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Customer</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.quotation?.all || allowedSections?.quotation?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/sales/quotations/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Quotation</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.proforma_invoice?.all || allowedSections?.proforma_invoice?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/sales/pro-invoices/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Proforma Invoice</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.sales_invoice?.all || allowedSections?.sales_invoice?.create) && (

                    <Link onClick={handleCloseMenu} to="/accounting/sales/invoices/add">
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Sales Invoice</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.receipt_voucher?.all || allowedSections?.receipt_voucher?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/sales/payments-received/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Receipt Voucher</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.sales_credit_note?.all || allowedSections?.sales_credit_note?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/sales/credit-notes/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Sales Credit Note</Typography>
                      </MenuItem>
                    </Link>
                    )}
                  </Box>
                </Box>
              )}
              {isPurchaseSection && (
                <Box className={`col-md-${!isPurchaseSection ? 12 : 4}`}>
                  <Box className="d-flex align-items-center gap-1">
                    <ShoppingCart />
                    <Typography variant="h5">PURCHASE</Typography>
                  </Box>
                  <Box className="flex-column mt-1">
                    {(allowedSections?.supplier_master?.all || allowedSections?.supplier_master?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/purchases/suppliers/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Supplier Master</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.expense?.all || allowedSections?.expense?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/purchases/expenses/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Expense</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.purchase_order?.all || allowedSections?.purchase_order?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/purchases/purchase-orders/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Purchase Order</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.purchase_invoice?.all || allowedSections?.purchase_invoice?.create) && (
                      <Link
                        onClick={handleCloseMenu}
                        to="/accounting/purchases/bills/add"
                      >
                        <MenuItem className="py-2 d-flex align-items-center">
                          <Add size={12} className="mr-2" />

                          <Typography>Purchase Invoice</Typography>
                        </MenuItem>
                      </Link>
                    )}
                    {(allowedSections?.payment_voucher?.all || allowedSections?.payment_voucher?.create) && (
                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/purchases/payments-made/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Payment Voucher</Typography>
                      </MenuItem>
                    </Link>
                    )}
                    {(allowedSections?.purchase_debit_note?.all || allowedSections?.purchase_debit_note?.create) && (

                    <Link
                      onClick={handleCloseMenu}
                      to="/accounting/purchases/debit-notes/add"
                    >
                      <MenuItem className="py-2 d-flex align-items-center">
                        <Add size={12} className="mr-2" />

                        <Typography>Purchase Debit Note</Typography>
                      </MenuItem>
                    </Link>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export default QuickCreateMenu;
