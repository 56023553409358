import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

// COMPONENTS & UTILS
import { FormikDatePicker, FormikField } from 'shared/form';
import FormikModernTimePicker from 'shared/form/FormikTimePicker';
import SubmitBtn from 'shared/form/SubmitBtn';
import {
  checkInFormInitialSchema,
  checkInOutFormInitialValue,
  checkOutFormInitialSchema,
} from '../../utilities/formUtils';

function CheckInOutForm({
  toggleForm,
  userId,
  attendanceID,
  isCheckIn,
  updateForgetToCheckOut,
  updateForgetToCheckIn,
  updateCheckInTime,
}) {
  // HANDLERS
  const handleFormSubmit = async (values, resetForm) => {
    let response;
    if (isCheckIn) {
      if (attendanceID) {
        response = await updateCheckInTime({
          id: attendanceID,
          employee: userId,
          check_in_time: values.check_in_time,
          forget_to_check_in_reason: values.forget_to_check_in_reason,
          date: moment().format('yyyy-MM-DD'),
        });
      } else {
        response = await updateForgetToCheckIn({
          date: moment().format('yyyy-MM-DD'),
          check_in_time: values.check_in_time,
          forget_to_check_in_reason: values.forget_to_check_in_reason,
          forget_to_check_in: true,
        });
      }
    } else {
      response = await updateForgetToCheckOut({
        date: values.date,
        check_out_time: values.check_out_time,
        id: attendanceID,
      });
    }
    if (!response.error) {
      resetForm();
      toggleForm();
    }
  };

  return (
    <Box>
      <Formik
        initialValues={checkInOutFormInitialValue}
        validationSchema={
          isCheckIn ? checkInFormInitialSchema : checkOutFormInitialSchema
        }
        onSubmit={async (values, { resetForm }) => handleFormSubmit(values, resetForm)}
      >
        {({ isSubmitting }) => (
          <Form className="d-flex align-items-start flex-column gap-3">
            <Grid container spacing={4} justifyContent="center">
              {!isCheckIn && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography variant="label" className="required">
                      Date
                    </Typography>
                    <FormikDatePicker name="date" isRequired={!isCheckIn} disableFuture />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className="required" variant="label">
                      Time
                    </Typography>
                    <FormikModernTimePicker
                      name="check_out_time"
                      isRequired={!isCheckIn}
                    />
                  </Grid>
                </>
              )}

              {isCheckIn && (
                <Grid item columnGap={2} xs={12}>
                  <FormikModernTimePicker
                    label="Time"
                    name="check_in_time"
                    isRequired={isCheckIn}
                    isRow
                  />

                  <FormikField
                    classes="mt-2"
                    label="Reason"
                    name="forget_to_check_in_reason"
                    isRequired={isCheckIn}
                    textArea
                    isRow
                  />
                </Grid>
              )}
            </Grid>

            <Stack direction="row" mt={2} spacing={1}>
              <SubmitBtn label="Submit" isLoading={isSubmitting} />
              {isCheckIn && (
                <Button
                  variant="mutedColor"
                  size="small"
                  onClick={toggleForm}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

CheckInOutForm.defaultProps = {
  toggleForm: () => {},
  updateForgetToCheckOut: () => {},
  updateForgetToCheckIn: () => {},
  updateCheckInTime: () => {},
  attendanceID: null,
  userId: null,
  isCheckIn: false,
};

CheckInOutForm.propTypes = {
  toggleForm: propTypes.func,
  updateForgetToCheckOut: propTypes.func,
  updateForgetToCheckIn: propTypes.func,
  updateCheckInTime: propTypes.func,
  attendanceID: propTypes.number,
  userId: propTypes.number,
  isCheckIn: propTypes.bool,
};

export default CheckInOutForm;
