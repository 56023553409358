/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { formatAmount } from 'utilities/helpers';
import { useGetTotalPayableDataQuery } from 'services/private/dashboard';
import CommonMenu from 'containers/common/components/CommonMenu';
import { Box, Paper, Grid, Typography, useTheme } from '@mui/material';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import { DEFAULT_CURRENCY } from 'utilities/constants';
import useAuthInfo from 'customHooks/useAuthInfo';
import useGetChartColorsArray from '../customHooks/useGetChartColorsArray';
import useGetPayablesTotalsData from '../customHooks/useGetPayablesTotalsData';

function TotalPayables() {
  const { company } = useAuthInfo();
  const { palette: { primary: { main, secondary } } } = useTheme();

  const { data: { data: totalPayablesData = [] } = {}, isLoading } = useGetTotalPayableDataQuery({ company: company?.id });

  const chartsColors = useGetChartColorsArray();

  const { currentAmount, overdueAmount, popupData, totalUnpaids } = useGetPayablesTotalsData(totalPayablesData, 'Bill');

  const dataForPieChart = [{
    name: 'Current Amount',
    value: Math.abs(currentAmount) === 0 ? 100 : Math.abs(currentAmount),
  },
  {
    name: 'Overdue Amount',
    value: Math.abs(overdueAmount),
  },];

  return (
    <Box className="">
      <Paper className="py-3 px-3">
        <Box className="pb-2">
          <Typography variant="h4">Your Payables</Typography>
        </Box>
        <Box className="p-0">
          {isLoading ? (
            <SectionLoader />
          ) : (
            <Grid container>
              <Grid item lg={6} md={6}>
                <Box>
                  <Typography variant="smallText">
                    Total overdue Purchase Invoices {DEFAULT_CURRENCY} {formatAmount(totalUnpaids)}
                  </Typography>
                </Box>
                <Box className="mt-1 d-flex gap-4 justify-content-between">
                  <Typography variant="body2" sx={{ color: main }}> Current ({DEFAULT_CURRENCY})</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}> {formatAmount(currentAmount)}</Typography>
                </Box>
                <Box className="mt-1 d-flex  gap-4 justify-content-between">
                  <Typography variant="body2" sx={{ color: secondary }}>   Overdue ({DEFAULT_CURRENCY})</Typography>
                  <CommonMenu menus={popupData} selectedOption={formatAmount(overdueAmount)} isOverdue />
                </Box>
              </Grid>
              <Grid item lg={6} md={6}>
                <ResponsiveContainer height={120}>
                  <PieChart>
                    <Pie
                      data={dataForPieChart}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={45}
                      outerRadius={60}
                    >
                      {
                        dataForPieChart.map(() => (

                          chartsColors.slice().reverse().map((color, index) => (
                            <Cell key={`cell-${index}`} fill={color} />
                          ))

                        ))
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default TotalPayables;
