import { privateAPI } from './index';

export const attendanceAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getShiftsSettingsList: build.query({
      query: params => ({
        url: 'attendance/employee-shift/',
        params
      }),
      providesTags: ['GetShiftsSettingsList'],
    }),
    addShiftsSetting: build.mutation({
      query: body => ({
        url: 'attendance/employee-shift/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),
    getShiftsSetting: build.query({
      query: id => ({
        url: `attendance/employee-shift/${id}/`,
      }),
      providesTags: ['GetShiftsSetting'],
    }),
    updateShiftsSetting: build.mutation({
      query: body => ({
        url: `attendance/employee-shift/${body?.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),

    deleteShiftsSetting: build.mutation({
      query: id => ({
        url: `attendance/employee-shift/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),
  }),
});

export const {
  useGetShiftsSettingsListQuery,
  useAddShiftsSettingMutation,
  useGetShiftsSettingQuery,
  useUpdateShiftsSettingMutation,
  useDeleteShiftsSettingMutation,
} = attendanceAPI;
