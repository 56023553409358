import moment from 'moment';
import { privateAPI } from './index';

export const companyActivitiesAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getCompanyActivities: build.query({
      query: params => ({
        url: '/activities/',
        method: 'GET',
        params,
      }),
      providesTags: ['allActivity'],
    }),
    getCompanyUpcomingActivities: build.query({
      query: params => ({
        url: '/activities/latest/',
        method: 'GET',
        params
      }),
      providesTags: ['latestActivity'],
    }),
    getCompanyActivity: build.query({
      query: id => ({
        url: `/activities/${id}/`,
        method: 'GET',
      }),
      providesTags: ['activity'],
    }),
    addCompanyActivity: build.mutation({
      query: values => {
        const payload = {
          start_time: values.start_time
            ? moment(values.start_time, ['h:mm A']).format('HH:mm')
            : '',
          end_time: values.end_time
            ? moment(values.end_time, ['h:mm A']).format('HH:mm')
            : '',
          start_date: values.start_date
            ? moment(values.start_date).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
          end_date: values.end_date
            ? moment(values.end_date).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
          title: values.title,
          description: values.description,
          participants: values.participants,
          company: values.company
        };
        return {
          url: '/activities/',
          body: payload,
          method: 'POST',
        };
      },
      invalidatesTags: ['allActivity', 'latestActivity', 'activity'],
    }),
    editCompanyActivity: build.mutation({
      query: values => {
        const payload = {
          start_time: values.start_time
            ? moment(values.start_time, ['h:mm A']).format('HH:mm')
            : '',
          end_time: values.end_time
            ? moment(values.end_time, ['h:mm A']).format('HH:mm')
            : '',
          start_date: values.start_date
            ? moment(values.start_date).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
          end_date: values.end_date
            ? moment(values.end_date).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
          title: values.title,
          description: values.description,
          participants: values.participants,
          company: values.company
        };
        return {
          url: `activities/${values.id}/`,
          body: payload,
          method: 'PUT',
        };
      },
      invalidatesTags: ['allActivity', 'latestActivity', 'activity'],
    }),
    deleteCompanyActivity: build.mutation({
      query: id => ({
        url: `/activities/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['allActivity', 'latestActivity', 'activity'],
    }),
  }),
});

export const {
  useGetCompanyActivitiesQuery,
  useGetCompanyUpcomingActivitiesQuery,
  useGetCompanyActivityQuery,
  useAddCompanyActivityMutation,
  useEditCompanyActivityMutation,
  useDeleteCompanyActivityMutation,
  useLazyGetCompanyActivitiesQuery,
} = companyActivitiesAPI;
