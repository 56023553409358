import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Box, FormHelperText, Stack } from '@mui/material';

import { fileInputBox } from 'styles/containers/forms';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { isValidURL } from 'utilities/helpers';

function FormikFileInput(props) {
  const { name, accept, className, multiple, onChange, ...restProps } = props;
  const [field, meta, helpers] = useField(name);
  const { value, onChange: onFieldValueChange, ...restFieldProps } = field;
  const { error } = meta;
  const { setValue } = helpers;

  const hasPreviewableImage = value && !Array?.isArray(value) && value?.type?.startsWith('image/');

  const isImgUrl = isValidURL(value);

  return (
    <Stack sx={{ width: '100%', borderRadius: '10px' }}>
      <Box sx={fileInputBox}>
        <input
          {...restProps}
          {...restFieldProps}
          type="file"
          id={name}
          accept={accept}
          multiple={multiple}
          onChange={event => {
            event.preventDefault();
            const target = multiple
              ? [...event.target.files]
              : event.target.files[0];
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
            setValue(target);
            if (onChange) onChange(target);
          }}
          style={{ display: 'none' }}
        />
        {/* Will preview only single image */}
        {(hasPreviewableImage || isImgUrl) ? (
          <div>
            <img src={isImgUrl ? value : URL.createObjectURL(value)} alt="" style={{ maxWidth: '100%' }} />
            <div className="d-flex justify-content-between" style={{ padding: '5px' }}>
              <label htmlFor={name} style={{ cursor: 'pointer' }}>
                Change File
              </label>
              <DeleteOutlineOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setValue(null)}
              />
            </div>
          </div>
        ) : (
          <label
            htmlFor={name}
            style={{ padding: '20px', width: '100%', cursor: 'pointer' }}
          >
            <figure>
              <InsertDriveFileOutlinedIcon
                style={{
                  color: 'grey',
                  fontSize: '60px',
                }}
              />
            </figure>
            <span style={{ color: 'grey', fontWeight: 'bold' }}>
              {multiple
                ? 'Upload Files'
                : (value && value.name) || 'Upload File'}
            </span>
          </label>
        )}
      </Box>
      {
       error && (
       <FormHelperText>{error}</FormHelperText>
       )
      }
      {
        multiple && value.length > 0 && (
          <Stack sx={{ marginTop: '10px' }}>
            <h4 style={{ fontWeight: 600 }}>Selected Files</h4>
            <ul>
              {value.map(item => (
                <li key={item.name}>{item.name}</li>
              ))}
            </ul>
          </Stack>
        )
      }
    </Stack>
  );
}

FormikFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

FormikFileInput.defaultProps = {
  disabled: false,
  multiple: false,
  className: '',
  accept: null,
  onChange: null,
};

export default FormikFileInput;
