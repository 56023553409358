/* eslint-disable object-shorthand */
import moment from 'moment';
import { privateAPI } from './index';

export const internalMeetingAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getInternalMeetings: build.query({
      query: params => ({
        url: 'InternalMeeting/internal_meeting_participants_list/',
        params }),
      providesTags: ['meetingsList']
    }),
    getInternalMeetingById: build.query({
      query: id => `InternalMeeting/${id}/`,
      providesTags: ['meetingsList']
    }),
    deleteInternalMeeting: build.mutation({
      query: id => ({
        url: `InternalMeeting/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['meetingsList']
    }),
    addInternalMeeting: build.mutation({
      query: values => {
        const newData = {
          from_time: values.from_time
            ? moment(values.from_time, ['h:mm A']).format('HH:mm')
            : '',
          to_time: values.to_time
            ? moment(values.to_time, ['h:mm A']).format('HH:mm')
            : '',
          meeting_date: values.meeting_date
            ? moment(values.meeting_date).format('YYYY-MM-DD')
            : '',
          title: values.title,
          description: values.description,
          participants: values.participants,
          location: values.location,
          company: values.company
        };
        return {
          url: 'InternalMeeting/',
          method: 'POST',
          body: newData
        };
      },
      invalidatesTags: ['meetingsList']
    }),
    editInternalMeeting: build.mutation({
      query: values => {
        const editedData = {
          from_time: values.from_time
            ? moment(values.from_time, ['h:mm A']).format('HH:mm')
            : '',
          to_time: values.to_time
            ? moment(values.to_time, ['h:mm A']).format('HH:mm')
            : '',
          meeting_date: values.meeting_date
            ? moment(values.meeting_date).format('YYYY-MM-DD')
            : '',
          title: values.title,
          description: values.description,
          participants: values.participants,
          location: values.location,
          company: values.company
        };

        return {
          url: `InternalMeeting/${values.id}/`,
          method: 'PUT',
          body: editedData
        };
      },
      invalidatesTags: ['meetingsList']
    }),
  }),
});

export const {
  useLazyGetInternalMeetingsQuery,
  useGetInternalMeetingsQuery,
  useGetInternalMeetingByIdQuery,
  useLazyGetInternalMeetingByIdQuery,
  useDeleteInternalMeetingMutation,
  useAddInternalMeetingMutation,
  useEditInternalMeetingMutation
} = internalMeetingAPI;
