import React from 'react';
import useAuthInfo from 'customHooks/useAuthInfo';
import { Box } from '@mui/material';
import OverviewTab from './HR/OverviewTab';
import DashboardTabsPanel from './components/DashboardTabsPanel';
import AnalyticsTab from './Accounting/AnalyticsTab';
import HRDashboard from './HR';

function Dashboard() {
  const {
    allowedSections
    // company
  } = useAuthInfo();

  if (allowedSections?.dashboard?.accounting) {
    return (
      <>
        {/* <Typography mt={2} variant="pageTitle">{isGlobalAdminstrator ? (
          <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Company Name: {company?.company_name}
          </span>
        ) : (
          ''
        )}
        </Typography> */}
        <DashboardTabsPanel
          tabs={[{
            index: 'overview',
            label: 'Overview',
            component: <OverviewTab />
          },
          {
            index: 'analytics',
            label: 'Inquisitive',
            component: <AnalyticsTab />
          },
          ]}
        />
      </>

    );
  }

  if (allowedSections?.dashboard?.hr) {
    return (
      <HRDashboard />
    );
  }

  return (
    <Box className="mt-4">
      <OverviewTab />
    </Box>
  );
}

export default Dashboard;
