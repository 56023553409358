import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import capitalize from 'lodash/capitalize';

const renderRecord = item => (
  <TableRow
    key={item.id}
  >
    <TableCell className="px-2 py-1">
      {item.user_profile_id && item.user_profile_id.employee_name
        ? item.user_profile_id.employee_name
        : '-'}
    </TableCell>
    <TableCell className="px-2 py-1">
      {item.leave_from_date
        ? moment(item.leave_from_date).format('DD MMM YYYY')
        : '-'}
    </TableCell>
    <TableCell className="px-2 py-1">
      {item.leave_to_date
        ? moment(item.leave_to_date).format('DD MMM YYYY')
        : '-'}
    </TableCell>
    <TableCell className="px-2 py-2">
      <Paper
        className="px-2 py-1"
        sx={{
          width: '70px',
          textAlign: 'center',
          fontSize: '12px',
          background: (item.is_approved === 'approved' && '#16bb22b0') ||
              (item.is_approved === 'denied' && '#ff0000b0') ||
              (item.is_approved === 'pending' && '#aaaaaa')
        }}
      >
        {item.is_approved ? capitalize(item.is_approved) : ''}
      </Paper>

    </TableCell>
  </TableRow>
);

function LeavesTable({ recentLeaves }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className="fw-bold">Employee</TableCell>
          <TableCell className="fw-bold">
            From
          </TableCell>
          <TableCell className="fw-bold">
            To
          </TableCell>
          <TableCell className="fw-bold">
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ fontSize: 13 }}>
        {recentLeaves && recentLeaves.length > 0 ? (
          [...recentLeaves].slice(0, 4).map(item => renderRecord(item))
        ) : (
          <TableRow>
            <TableCell
              colSpan="4"
            >
              No Recent Leaves
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

LeavesTable.propTypes = {
  recentLeaves: PropTypes.array,
};

LeavesTable.defaultProps = {
  recentLeaves: [],
};

export default LeavesTable;
