const sidebarStylings = {
  marginTop: '0rem',
};
export const sidebarExpand = {
  marginTop: sidebarStylings.marginTop,
  width: '209px',
};

export const sidebarCollapse = {
  marginTop: sidebarStylings.marginTop,
  width: '65px',
  overflowX: 'hidden',
};
