import { yupLessTimeValidator, yupTimeValidator } from 'utilities/helpers';
import * as yup from 'yup';

export const checkInOutFormInitialValue = {
  date: '',
  check_out_time: '',
  check_in_time: '',
  forget_to_check_in_reason: '',
};

export const checkInFormInitialSchema = yup.object({
  check_in_time: yup
    .string()
    .required('Required!')
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .test('test-less-time', 'Invalid time!', yupLessTimeValidator)
    .typeError('Invalid Time'),
  forget_to_check_in_reason: yup
    .string()
    .max(255, 'Maximum 255 characters allowed')
    .required('Required!'),
});

export const checkOutFormInitialSchema = yup.object({
  date: yup.date().required('Required!').typeError('Invalid Date'),
  check_out_time: yup
    .string()
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .required('Required!')
    .typeError('Invalid Time'),
});

export const editBreakFormInitialValue = {
  start_break_time: '',
  end_break_time: '',
  break_reason: '',
};

export const editBreakFormInitialSchema = yup.object({
  start_break_time: yup.string(),
  end_break_time: yup
    .string()
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .required('Required!'),
  break_reason: yup.string().required('Required!'),
});

export const searchFilterFormInitialValue = {
  profile_id: '',
  status: '',
  attendance_status: '',
  start_date: '',
  end_date: '',
  label: '',
  year: '',
  month: '',
};

export const searchFilterFormSchema = yup.object({
  profile_id: yup.string(),
  status: yup.string(),
  attendance_status: yup.string(),
  start_date: yup.date().typeError('Please enter a valid date'),
  end_date: yup
    .date()
    .typeError('Please enter a valid date')
    .min(yup.ref('start_date'), 'End date must be later than start date'),
});
