import React from 'react';
// import { Grid, Container, Row } from 'reactstrap';
import { Box, Grid, Paper, Typography } from '@mui/material';
import {
  useGetLatestMeetingsQuery,
  useGetNewlyHiredEmployeesQuery,
  useGetRecentLeavesQuery,
} from 'services/private/dashboard';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import AttendanceBar from 'containers/pages/attendance/components/AttendanceBar';
// import AnnoucmentSlider from 'containers/common/components/Announcements';
import useAuthInfo from 'customHooks/useAuthInfo';
import LeavesTable from './components/LeavesTable';
import UserCard from './components/UserCard';
import MeetingCard from './components/MeetingCard';
import DashBoardCalendar from '../DashboardCalender';
// import AnnoucmentSlider from '../../Common/Announcements';

function HRDashboard() {
  const { company } = useAuthInfo();
  const { data: newlyHiredUsers, isLoading: newlyHiredUsersLoading } =
    useGetNewlyHiredEmployeesQuery({ company: company?.id });
  const { data: latestMeetings, isLoading: latestMeetingsLoading } =
    useGetLatestMeetingsQuery({ company: company?.id });
  const { data: recentLeaves, isLoading: recentLeavesLoading } =
    useGetRecentLeavesQuery({ company: company?.id });

  if (newlyHiredUsersLoading || latestMeetingsLoading || recentLeavesLoading) {
    return <SectionLoader />;
  }
  return (
    <>
      <MetaTagsHelmet moduleName="Dashboard" />
      {/* <Box className="mt-3">
        <AnnoucmentSlider />
      </Box> */}
      <Box className="mt-3">
        <AttendanceBar />
      </Box>
      <Grid spacing={2} container className="my-2">
        <Grid xl={6} lg={6} md={12} sm={12} item>
          <Paper className="py-3 px-2">
            <Typography variant="body1" className="fw-bold" sx={{ marginBottom: '0px' }}>
              New Joiners
            </Typography>
          </Paper>
          <Grid className="mt-2" container>
            {newlyHiredUsers &&
              newlyHiredUsers.length > 0 &&
              newlyHiredUsers.map(user => <UserCard user={user} key={user.email} />)}
          </Grid>
        </Grid>
        <Grid xl={6} lg={6} md={12} sm={12} item>
          <Paper className="p-3" sx={{ minHeight: '100%' }}>
            <Typography variant="body1" className="fw-bold">
              Most Recent Requests
            </Typography>
            <LeavesTable recentLeaves={recentLeaves} />
          </Paper>
        </Grid>
        <Grid xl={6} lg={6} md={12} sm={12} item>
          <Paper className="p-4" sx={{ minHeight: '100%' }}>
            <Typography variant="body1" className="fw-bold">
              Meetings & Activity
            </Typography>
            <Box className="dashboard-calendar-meeting">
              <DashBoardCalendar />
            </Box>
          </Paper>
        </Grid>
        <Grid xl={6} lg={6} md={12} sm={12} item>
          <Paper className="p-4" sx={{ minHeight: '100%' }}>
            <Typography variant="body1" className="fw-bold">
              Most Recent Meetings
            </Typography>
            {latestMeetings && latestMeetings.length > 0 ? (
              <Box>
                {latestMeetings.map(meeting => (
                  <MeetingCard meeting={meeting} key={meeting.id} />
                ))}
              </Box>
            ) : (
              <Box
                sx={{ display: 'flex', minHeight: '300px', flexGrow: 1 }}
                className=" flex-column justify-content-center align-items-center"
              >
                <Typography variant="h4">No meeting found</Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default HRDashboard;
