import React from 'react';
import withTable from 'hoc/withTable';
import propTypes from 'prop-types';
import { Paper, Table, TableContainer } from '@mui/material';

// HOOKS & API
import {
  useDeleteDailyReportsMutation,
  useUpdateDailyReportsMutation,
} from 'services/private/dailyReports';

// COMPONENTS & UTILS
import TableLoader from 'containers/common/components/TableLoader';
import CommonDeleteModal from 'containers/common/components/CommonDeleteModal';
import CommonModal from 'containers/common/components/CommonModal';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import DailyReportsTableBody from './DailyReportsTableBody';
import DailyReportsTableHead from './DailyReportsTableHead';
import DailyReportForm from './DailyReportForm';

function DailyReportsTable({ tableProps, data, loading }) {
  const {
    order,
    orderBy,
    selected,
    setSelected,
    deleteModal,
    deleteToggle,
    editModal,
    editToggle,
  } = tableProps;

  const [deleteDailyReports] = useDeleteDailyReportsMutation();
  const [updateDailyReport, { error: editReportError, isSuccess: editReportSuccess }] =
    useUpdateDailyReportsMutation();

  useHandleApiResponse(
    editReportError,
    editReportSuccess,
    'Daily Report Updated Successfully!',
    '',
    false
  );

  return (
    <>
      <CommonDeleteModal
        deleteHandler={deleteDailyReports}
        deleteModal={deleteModal}
        deleteToggle={deleteToggle}
        selected={[selected?.id]}
        setSelected={setSelected}
        message={`Are you sure you want to delete the Report${
          selected.length > 1 ? 's' : ''
        }?`}
      />

      <CommonModal isOpen={editModal} toggle={editToggle} title="Edit Report">
        <DailyReportForm
          formData={selected}
          handler={updateDailyReport}
          afterRequestSend={editToggle}
          responseHandler={resp => {
            if (!resp.error) {
              editToggle();
            }
          }}
          attendanceID={data?.id}
        />
      </CommonModal>

      <TableContainer className=" mt-4 mb-4" component={Paper}>
        <Table stickyHeader>
          <DailyReportsTableHead order={order} orderBy={orderBy} />
          {loading ? (
            <TableLoader colSpan={8} />
          ) : (
            <DailyReportsTableBody
              editToggle={editToggle}
              deleteToggle={deleteToggle}
              setSelected={setSelected}
              data={data?.daily_report}
              reportSubmitted={data?.is_report_submitted}
            />
          )}
        </Table>
      </TableContainer>
    </>
  );
}

DailyReportsTable.defaultProps = {
  data: {},
  loading: false,
};

DailyReportsTable.propTypes = {
  tableProps: propTypes.object.isRequired,
  data: propTypes.object,
  loading: propTypes.bool,
};

export default withTable(DailyReportsTable);
