import useAuthInfo from 'customHooks/useAuthInfo';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetForgetCheckOutQuery } from 'services/private/attendance';

function ForgetCheckoutRoute() {
  const { company } = useAuthInfo();
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery({ company: company?.id });
  // Before going to portal routes, Checking report submitted and checkout status for forget to checkout
  return ForgetCheckOut && ForgetCheckOut?.report_submitted && !ForgetCheckOut?.check_out ? (
    <Navigate to="/" replace />
  ) : (
    <Outlet />
  );
}

export default ForgetCheckoutRoute;
