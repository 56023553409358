import React from 'react';

import { Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';

function BirthdayComponent({ image, handleClose, toggle }) {
  return (
    <Box
      sx={{
        backgroundColor: '#f9fbe7', // Light background for a festive feel
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        p: 3,
        textAlign: 'center',
        // maxWidth: 600,
        margin: 'auto',
      }}
    >
      {/* Birthday Message Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          mb: 4,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              letterSpacing: 2,
              //   color: '#FF5722', // Bright orange color
              mb: 1,
            }}
          >
            🎉 HAPPY BIRTHDAY 🎉
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#616161',
              mb: 2,
              fontStyle: 'italic',
            }}
          >
            Wishing you all the best on your special day!
          </Typography>
        </Box>
        <Box sx={{ flex: 2, maxWidth: 500 }}>
          <img
            src={image}
            alt="birthday"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.3s', // Animation for image on hover
            }}
            // onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.05)')}
            // onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
          />
        </Box>
      </Box>

      {/* Button Section */}
      <Box className="d-flex gap-2" sx={{ justifyContent: 'center', mt: 4 }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{
            px: 4,
            py: 1.5,
          }}
        >
          Thank You
        </Button>

        <Button
          onClick={toggle}
          variant="outlined"
          sx={{
            px: 4,
            py: 1.5,
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}

export default BirthdayComponent;
BirthdayComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  image: PropTypes.any.isRequired,
  toggle: PropTypes.func.isRequired,
};
