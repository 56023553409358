import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Add } from '@mui/icons-material';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// HOOKS & API
import {
  useGetAttendanceReportsQuery,
  useGetForgetCheckOutQuery,
  useUpdateBreakTimeManuallyMutation,
  useUpdateCheckOutMutation,
} from 'services/private/attendance';
import {
  useAddDailyReportsMutation,
  useUpdateSubmitReportMutation,
} from 'services/private/dailyReports';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import useGetParams from 'customHooks/useGetParams';

// COMPONENTS & UTILS
import CommonModal from 'containers/common/components/CommonModal';
import ConfirmationPopup from 'containers/common/components/ConfirmationPopup';
import useAuthInfo from 'customHooks/useAuthInfo';
import Analysis from './components/Analysis';
import AttendanceActionBtn from '../components/AttendanceActionBtn';
import DailyReportsTable from './components/DailyReportsTable';
import DailyReportForm from './components/DailyReportForm';
import EditBreakForm from '../components/forms/EditBreakForm';

function DailyReports() {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const primaryBg = palette.primary.main;

  const [apiParams] = useGetParams();
  const { company } = useAuthInfo();

  //   STATES
  const [dailyReportFormOpen, setDailyReportFormOpen] = useState(false);
  const [editBreakFormOpen, setEditBreakFormOpen] = useState(false);
  const [isSubmitModalOpen, setSubmitModalOpen] = useState(false);
  const toggleDailyReportFormOpen = () => setDailyReportFormOpen(state => !state);
  const toggleEditBreakFormOpen = () => setEditBreakFormOpen(state => !state);

  //   API HOOKS
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery({ company: company?.id });

  const {
    data: checkInData,
    isLoading: attendanceLoading,
    isFetching,
  } = useGetAttendanceReportsQuery({ attendanceId: apiParams?.attendance });

  const [updateSubmitReport, { error, isSuccess, isLoading }] =
    useUpdateSubmitReportMutation();
  const [updateCheckOut, { error: checkOutError, isSuccess: checkOutSuccess }] =
    useUpdateCheckOutMutation();

  const [addDailyReport, { error: addReportError, isSuccess: addReportSuccess }] =
    useAddDailyReportsMutation();

  const [updateBreakTimeManually, { error: breakError, isSuccess: breakSucess }] =
    useUpdateBreakTimeManuallyMutation();
  useHandleApiResponse(
    breakError,
    breakSucess,
    'Break Updated Successfully!',
    '',
    false
  );

  useHandleApiResponse(
    addReportError,
    addReportSuccess,
    'Daily Report Added Successfully!',
    '',
    false
  );

  //   HANDLERS
  useHandleApiResponse(
    checkOutError,
    checkOutSuccess,
    'Checked Out Successfully!',
    '',
    false
  );
  useHandleApiResponse(
    error,
    isSuccess,
    'Daily Report Submitted Successfully!',
    '',
    false
  );

  const handleSubmitReport = async () => {
    const res = await updateSubmitReport({
      is_report_submitted: true,
      id: checkInData?.id,
    });
    setSubmitModalOpen(false);
    if (!res?.error && apiParams?.check_out) {
      const timeFormatted = moment().format('HH:mm:ss');
      const dateFormatted = moment().format('yyyy-MM-DD');
      await updateCheckOut({
        date: dateFormatted,
        check_out_time: timeFormatted,
        id: checkInData?.id,
      });
      navigate('/');
    }
  };

  const breakData = useMemo(() => {
    const lastIndex = (checkInData?.break_time.length ?? 1) - 1;
    const latestData = checkInData?.break_time[lastIndex];
    return latestData;
  }, [checkInData?.break_time]);

  //   EFFECTS
  useEffect(() => {
    if (
      ForgetCheckOut &&
      ForgetCheckOut?.report_submitted &&
      !ForgetCheckOut?.check_out
    ) {
      navigate('/');
    }
  }, [ForgetCheckOut, ForgetCheckOut?.check_out, ForgetCheckOut?.report_submitted]);

  useEffect(() => {
    if (breakData && !breakData?.end_break_time && !ForgetCheckOut?.report_submitted) {
      setEditBreakFormOpen(true);
    } else {
      setEditBreakFormOpen(false);
    }
  }, [breakData, breakData?.end_break_time]);

  //   HANDLERS
  const handleSubmitPopup = () => setSubmitModalOpen(prevState => !prevState);

  return (
    <>
      <Box className="mb-4 mt-2">
        <Typography variant="pageTitle">Daily Task</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card className="p-4">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h3" fontWeight={600}>
                What I have Done Today?
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  onClick={toggleDailyReportFormOpen}
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  disabled={!checkInData || checkInData?.is_report_submitted}
                >
                  Add Report
                </Button>
              </Stack>
            </Stack>
            <Box>
              <DailyReportsTable
                data={checkInData}
                loading={attendanceLoading || isFetching}
              />
            </Box>
            <AttendanceActionBtn
              className="mt-4 w-100"
              size="large"
              isLoading={isLoading}
              label="Submit"
              onClick={handleSubmitPopup}
              isDisabled={!checkInData || checkInData?.is_report_submitted}
            />
          </Card>
        </Grid>

        {/* Add Daily Report Form */}
        <CommonModal
          isOpen={dailyReportFormOpen}
          toggle={toggleDailyReportFormOpen}
          title="Add Report"
        >
          <DailyReportForm
            afterRequestSend={toggleDailyReportFormOpen}
            handler={addDailyReport}
            responseHandler={resp => {
              if (!resp.error) {
                toggleDailyReportFormOpen();
              }
            }}
            attendanceID={checkInData?.id}
          />
        </CommonModal>

        {/* Edit Break Form */}
        <CommonModal
          isOpen={editBreakFormOpen}
          toggle={() => {}}
          title="End Your Break to Proceed"
        >
          <Box
            sx={{
              width: '500px',
              padding: '20px',
            }}
          >
            <EditBreakForm
              updateBreakTimeManually={updateBreakTimeManually}
              toggle={toggleEditBreakFormOpen}
              breakData={breakData}
              breakForget
            />
          </Box>
        </CommonModal>

        <ConfirmationPopup
          isOpen={isSubmitModalOpen}
          toggle={handleSubmitPopup}
          onConfirmation={handleSubmitReport}
          message="Are you sure you want to Submit the Daily Report?"
        />

        <Grid item xs={12} md={4} className="d-flex flex-column gap-3">
          <Card sx={{ backgroundColor: primaryBg }} className="p-4">
            <Typography color="white" fontWeight={600} variant="h6">
              {'Today\'s Quote'}
            </Typography>
            <Typography mt={2} color="white" variant="body2">
              “Embrace your uniqueness, and let your true self shine.”{' '}
            </Typography>
          </Card>

          <Card className="p-4">
            <Typography variant="h6" fontWeight={600}>
              {'Today\'s Time Analysis'}
            </Typography>
            <Analysis
              productiveHours={
                checkInData && !checkInData?.is_report_submitted
                  ? checkInData?.productive_hours
                  : '0h 0m'
              }
              presentHours={
                checkInData && !checkInData?.is_report_submitted
                  ? checkInData?.total_hours
                  : '0h 0m'
              }
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default DailyReports;
