import React from 'react';
import { Box, Button, Toolbar, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CommonModal from './CommonModal';

function ConfirmationPopup({
  isOpen,
  toggle,
  message,
  onConfirmation,
  onCancel,
}) {
  const { palette: { primary: { main } } } = useTheme();

  return (

    <CommonModal
      isOpen={isOpen}
      toggle={toggle}
      isPopup
    >
      <Box sx={{ maxWidth: '350px', minWidth: '350px' }}>
        <Box className="d-flex justify-content-center">
          <WarningAmberRoundedIcon style={{ fontSize: '4rem', color: main }} />
        </Box>
        <Typography variant="h3" sx={{ fontSize: '22px', textAlign: 'center', }}>Attention!</Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>{message}</Typography>
        <Toolbar className="justify-content-center gap-2">
          <Button
            variant="mutedColor"
            size="small"
            onClick={() => {
              if (onCancel) onCancel();
              else toggle();
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={onConfirmation}
          >
            Yes
          </Button>
        </Toolbar>
      </Box>
    </CommonModal>
  );
}

ConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ConfirmationPopup.defaultProps = {
  onCancel: null,
};

export default ConfirmationPopup;
