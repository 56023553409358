import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function renderInfo(label, values) {
  return (
    <Box className="row">
      <Typography variant="body1" className="fw-bold col-lg-4 col-md-4">{label}</Typography>
      <Typography variant="body1" className="col-lg-8 col-md-8">{values}</Typography>
    </Box>
  );
}

function HolildayDetail({ selectedHoliday }) {
  const isActivityRecord = selectedHoliday.event_index === 1;
  return (
    <div>
      {renderInfo(isActivityRecord ? 'Activity Name:' : 'Title:', selectedHoliday?.title)}
      {renderInfo('Description:', selectedHoliday?.description)}
      {renderInfo('Start Date:', selectedHoliday && moment(selectedHoliday.start).format('YYYY/MM/DD'))}
      {renderInfo('End Date:', selectedHoliday && moment(selectedHoliday.end).format('YYYY/MM/DD'))}
      {selectedHoliday?.start_time && renderInfo('Start Time:', selectedHoliday.start_time)}
      {selectedHoliday?.end_time && renderInfo('End Time:', selectedHoliday.end_time)}
    </div>
  );
}
HolildayDetail.propTypes = {
  selectedHoliday: PropTypes.object,
};
HolildayDetail.defaultProps = {
  selectedHoliday: {},
};

export default HolildayDetail;
