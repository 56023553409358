import React from 'react';
import { IconButton, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import propTypes from 'prop-types';
import { Delete, Edit } from '@mui/icons-material';
import NoFoundTable from 'containers/common/components/NoFoundTable';

function DailyReportsTableBody({ data, reportSubmitted, editToggle, deleteToggle, setSelected }) {
  if (data?.length === 0 || reportSubmitted) {
    return <NoFoundTable col={4} message="No data found!" />;
  }

  return (
    <TableBody>
      {data?.map(item => (
        <TableRow key={item.id}>

          <TableCell>{item?.project}</TableCell>

          <TableCell>{item?.productive_hours}</TableCell>

          <TableCell>{item.mins}</TableCell>

          <TableCell>
            <Stack direction="row" gap={0.5}>
              <IconButton
                onClick={() => {
                  editToggle();
                  setSelected(item);
                }}
                title="Edit"
                size="small"
                disabled={data?.is_report_submitted}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  deleteToggle();
                  setSelected(item);
                }}
                title="Delete"
                size="small"
                disabled={data?.is_report_submitted}
              >
                <Delete />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

DailyReportsTableBody.defaultProps = {
  data: [],
  reportSubmitted: false,
};

DailyReportsTableBody.propTypes = {
  data: propTypes.array,
  reportSubmitted: propTypes.bool,
  editToggle: propTypes.func.isRequired,
  deleteToggle: propTypes.func.isRequired,
  setSelected: propTypes.func.isRequired,
};

export default DailyReportsTableBody;
