import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  openedFolderId: null,
  openedFolderName: null,
  parentFolderId: null,
  fetched: null,
};
let i = 0;

const mapFolders = item => ({
  id: Date.now() + i++,
  itemId: item.id,
  name: item.fold_name,
  type: item.type,
  owner: item.department,
  // user_profile_id: item.user_profile_id,
  created_by: item.created_by,
  createdAt: item.created_at,
});

const mapFiles = item => ({
  id: Date.now() + i++,
  itemId: item.id,
  name: item.doc_name,
  type: item.type,
  owner: '',
  createdAt: item.created_at,
  docType: item.doc_type,
  docFile: item.doc_file,
  category: item.location,
  // user_profile_id: item.user_profile_id,
  created_by: item.created_by,
});

const userFilesSlice = createSlice({
  name: 'userFiles',
  initialState,
  reducers: {
    setUserFoldersData: (state, action) => ({
      ...state,
      items: [
        ...action.payload.folders.map(mapFolders),
        ...action.payload.storage.map(mapFiles),
      ],
      openedFolderId: action.payload.folder_id,
      openedFolderName: action.payload.folder_name,
      parentFolderId: action.payload.parent_folder_id,
    }),
    addToHeirarchy: (state, action) => ({
      ...state,
      foldersHierarchy: [...state.foldersHierarchy, action.payload],
    }),
    removeFromHeirarchy: (state, action) => ({
      ...state,
      foldersHierarchy: state.foldersHierarchy.filter(
        item => item.id !== action.payload
      ),
    }),
    clearUserFile: state => ({
      ...state,
      fetched: null,
    })
  },
});

export const { setUserFoldersData, addToHeirarchy, removeFromHeirarchy, clearUserFile } = userFilesSlice.actions;
export default userFilesSlice.reducer;
