import React from 'react';
import propTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from './ConfirmationPopup';

function CommonDeleteModal({
  deleteHandler,
  deleteModal,
  deleteToggle,
  selected,
  setSelected,
  message
}) {
  const { enqueueSnackbar } = useSnackbar();

  // HANDLERS
  const handleDeleteSelected = async () => {
    selected?.forEach(async item => {
      const response = await deleteHandler(item);
      setSelected([]);
      deleteToggle();
      if (!response.error) {
        enqueueSnackbar('Deleted Successfully!', { variant: 'success' });
      } else {
        enqueueSnackbar(response?.error?.data?.message, { variant: 'error' });
      }
    });
  };
  return (
    <ConfirmationPopup
      isOpen={deleteModal}
      toggle={deleteToggle}
      onConfirmation={handleDeleteSelected}
      message={message}
    />
  );
}

CommonDeleteModal.propTypes = {
  setSelected: propTypes.func.isRequired,
  selected: propTypes.array,
  deleteToggle: propTypes.func.isRequired,
  deleteModal: propTypes.bool.isRequired,
  deleteHandler: propTypes.func.isRequired,
  message: propTypes.string,
};

CommonDeleteModal.defaultProps = {
  selected: [],
  message: '',
};

export default CommonDeleteModal;
