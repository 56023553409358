import moment from 'moment';
import { privateAPI } from './index';

export const announcementsAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getAnnouncementsList: build.query({
      query: params => ({
        url: 'announcements/',
        params,
        method: 'GET',
      }),
      providesTags: ['announcementsList'],
    }),
    getLatestAnnouncements: build.query({
      query: params => ({
        url: 'announcements/active/',
        params,
        method: 'GET',
      }),
      providesTags: ['announcementsList'],
    }),
    addAnnouncement: build.mutation({
      query: values => {
        let payload = {};
        if (values.attachment !== null && typeof values.attachment === 'object') {
          const formData = new FormData();
          formData.append(
            'end_date',
            values.date ? moment(values.date).format('YYYY-MM-DD') : ''
          );
          formData.append('subject', values.subject);
          formData.append('description', values.description);
          formData.append('file', values.attachment);
          formData.append('company', values.company);
          payload = formData;
        } else {
          payload = {
            end_date: values.date ? moment(values.date).format('YYYY-MM-DD') : '',
            subject: values.subject,
            description: values.description,
            file: values.attachment ? values.attachment : null,
            company: values.company
          };
        }

        return {
          url: 'announcements/',
          method: 'POST',
          body: payload
        };
      },
      invalidatesTags: ['announcementsList'],
    }),
    editAnnouncement: build.mutation({
      query: values => {
        let payload = {};
        if (values.attachment !== null && typeof values.attachment === 'object') {
          const formData = new FormData();
          formData.append(
            'end_date',
            values.date ? moment(values.date).format('YYYY-MM-DD') : ''
          );
          formData.append('subject', values.subject);
          formData.append('description', values.description);
          formData.append('file', values.attachment);
          formData.append('company', values.company);
          payload = formData;
        } else {
          payload = {
            end_date: values.date ? moment(values.date).format('YYYY-MM-DD') : '',
            subject: values.subject,
            description: values.description,
            file: values.attachment ? values.attachment : null,
            company: values.company
          };
        }

        return {
          url: `announcements/${values.id}/`,
          method: 'PUT',
          body: payload
        };
      },
      invalidatesTags: ['announcementsList'],
    }),
    getAnnouncement: build.query({
      query: id => ({
        url: `announcements/${id}/`,
        method: 'GET',
      }),
      providesTags: ['announcementDetail'],
    }),
    annoucementViewed: build.query({
      query: id => ({
        url: `announcements/${id}/viewed/`,
        method: 'GET',
      }),
      providesTags: ['announcementDetail'],
    }),
    deleteAnnouncement: build.mutation({
      query: id => ({
        url: `announcements/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['announcementsList'],
    }),
  }),
});

export const {
  useGetAnnouncementsListQuery,
  useGetAnnouncementQuery,
  useLazyGetAnnouncementQuery,
  useAddAnnouncementMutation,
  useEditAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useAnnoucementViewedQuery,
  useGetLatestAnnouncementsQuery,
} = announcementsAPI;
