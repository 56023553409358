/* eslint-disable object-shorthand */
import { useSelector } from 'react-redux';
import { privateAPI } from './index';

export const usersAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getRecentActivities: build.query({
      query: () => '/user/recent/activity/',
    }),
    recordActivity: build.mutation({
      query: values => {
        const { id } = useSelector(state => state.auth.user);

        const data = {
          type: values.type,
          description: values.description,
          title: values.title,
          object_id: values.id,
          user: id,
        };

        return {
          url: '/user/recent/activity/',
          method: 'POST',
          body: data,
        };
      },
    }),
    getUsersByStatus: build.query({
      query: ({ participants, company }) => ({
        url: `/users/search/status/${participants}`,
        params: company }),
      providesTags: ['usersList']
    }),
    getUsersByStatusWithCal: build.query({
      query: value => {
        let employeStatus = '';
        if (typeof value === 'object') {
          employeStatus = value.status || 'all';
        }
        if (typeof value === 'string') employeStatus = value || 'all';

        return {
          url: `/users/search/status/${employeStatus}`,
          method: 'GET',
          params: {
            company: value.company,
          }
        };
      }
    }),
    getUsers: build.query({
      query: params => ({
        url: '/users/',
        params }),
      providesTags: ['usersList']
    }),
    getImmediateSupervisors: build.query({
      query: ({ department, company }) => `ImmediateSupervisor/immediate_supervisor/?department=${department}&company=${company}`,
    }),
    getBirthdayAcknowledgement: build.query({
      query: () => 'acknowledge-birthday/',
      invalidatesTags: ['loadUser']
    }),
  }),
});

export const {
  useLazyGetBirthdayAcknowledgementQuery,
  useGetRecentActivitiesQuery,
  useRecordActivityMutation,
  useGetUsersByStatusQuery,
  useGetUsersQuery,
  useGetImmediateSupervisorsQuery,
  useGetUsersByStatusWithCalQuery
} = usersAPI;
