import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import propTypes from 'prop-types';

function SectionLoader({ height }) {
  const { palette } = useTheme();
  const primary = palette.primary.main;

  return (
    <Box
      className="d-flex align-items-center justify-content-center w-100"
      sx={{ minHeight: '250px', height: `${height}vh`, background: 'white' }}
    >

      <ThreeDots
        visible
        height="100"
        width="100"
        color={primary}
        radius="9"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
}

SectionLoader.propTypes = {
  height: propTypes.number,
};

SectionLoader.defaultProps = {
  height: 20,
};

export default SectionLoader;
