import { Box, Paper, Typography } from '@mui/material';
import propTypes from 'prop-types';
import React from 'react';
import { attendanceCard, attendanceCardIcons } from 'styles/mui/containers/layoutStyles';

function CommonAttendanceCard({ icon, title, data }) {
  return (
    <Box sx={attendanceCard} textAlign="center">
      <Paper sx={attendanceCardIcons} square={false} elevation={2}>
        {icon}
      </Paper>
      <Typography className="mb-1" variant="body1" fontWeight={300}>
        {title}
      </Typography>
      <Typography variant="h3" fontWeight={600} color="primary">
        {data}
      </Typography>
    </Box>
  );
}

CommonAttendanceCard.propTypes = {
  icon: propTypes.element.isRequired,
  title: propTypes.string.isRequired,
  data: propTypes.string.isRequired,
};

export default CommonAttendanceCard;
