/* eslint-disable react/no-array-index-key */

import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { useGetTotalReceivableDataQuery } from 'services/private/dashboard';
import { formatAmount } from 'utilities/helpers';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import CommonMenu from 'containers/common/components/CommonMenu';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import { DEFAULT_CURRENCY } from 'utilities/constants';
import useAuthInfo from 'customHooks/useAuthInfo';
import useGetTotalsData from '../customHooks/useGetTotalsData';
import useGetChartColorsArray from '../customHooks/useGetChartColorsArray';

function TotalReceiveables() {
  const { company } = useAuthInfo();
  const { data: { data: totalReceivablesData = [] } = {}, isLoading } = useGetTotalReceivableDataQuery({ company: company?.id });

  const { palette: { primary: { main, secondary } } } = useTheme();

  const chartsColors = useGetChartColorsArray();

  const { currentAmount, overdueAmount, popupData, totalUnpaids } = useGetTotalsData(totalReceivablesData, 'Invoice');

  const dataForPieChart = [{
    name: 'Current Amount',
    value: Math.abs(currentAmount) === 0 ? 100 : Math.abs(currentAmount),
  },
  {
    name: 'Overdue Amount',
    value: Math.abs(overdueAmount),
  },];

  return (
    <Box>
      <Paper className="py-3 px-3">
        <Box className="pb-2">
          <Typography variant="h4">Your Receivables</Typography>
        </Box>
        <Box className="p-0">
          {
            isLoading ? (
              <SectionLoader />
            ) : (
              <Grid container>
                <Grid item lg={6} md={6}>
                  <Box>
                    <Typography variant="smallText">
                      Total overdue invoices {DEFAULT_CURRENCY} {formatAmount(totalUnpaids)}
                    </Typography>
                  </Box>
                  <Box className="mt-1 d-flex gap-4 justify-content-between">
                    <Typography variant="body2" sx={{ color: main }}> Current ({DEFAULT_CURRENCY})</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}> {formatAmount(currentAmount)}</Typography>
                  </Box>
                  <Box className="mt-1 d-flex gap-4 justify-content-between">
                    <Typography variant="body2" sx={{ color: secondary }}>   Overdue ({DEFAULT_CURRENCY})</Typography>
                    <CommonMenu menus={popupData} selectedOption={formatAmount(overdueAmount)} isOverdue />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6}>
                  <ResponsiveContainer height={120}>
                    <PieChart>
                      <Pie
                        data={dataForPieChart}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={45}
                        outerRadius={60}
                      >
                        {
                          dataForPieChart.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={chartsColors[index % chartsColors.length]} />
                          ))
                        }
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )
          }

        </Box>
      </Paper>
    </Box>
  );
}

export default TotalReceiveables;
