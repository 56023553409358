import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, Modal } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import { mainContainerStyles } from 'styles/mui/containers/layoutStyles';
import { useLazyGetBirthdayAcknowledgementQuery } from 'services/private/users';
import image from 'assets/birthday.webp';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import BirthdayComponent from './components/Birthday';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#f9fbe7', // Light background for a festive feel
  borderRadius: '12px',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  p: 3,
  textAlign: 'center',
  maxWidth: 900,
  margin: 'auto',
};
function LayoutWrapper() {
  const { isAuthenticated, profile } = useAuthInfo();
  const [setBirthdayAcknowledgement] = useLazyGetBirthdayAcknowledgementQuery();
  const today = new Date();
  const birthdayAcknowledgeYear = new Date(profile?.birthday_acknowledge_date);
  const dob = new Date(profile?.dob);
  const birthdayDateCheck =
    today.getMonth() <= dob.getMonth() &&
    today.getDate() <= dob.getDate() &&
    (today.setHours(0, 0, 0, 0) > birthdayAcknowledgeYear.setHours(0, 0, 0, 0) ||
      profile?.birthday_acknowledge_date === null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleToggleSidebar = () => {
    setSidebarOpen(prevState => !prevState);
  };
  const toggle = () => setOpen(!open);
  const handleClose = () => {
    setBirthdayAcknowledgement();
    toggle();
  };
  useEffect(() => {
    if (birthdayDateCheck) {
      setOpen(true);
    }
  }, [profile]);

  return (
    isAuthenticated && (
      <Box>
        <Topbar isOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />

        <Box className="d-flex align-items-start w-100">
          <Sidebar isOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />
          {/* <Box> */}
          <Container sx={mainContainerStyles(isSidebarOpen)} className="p-1">
            <Modal
              open={open}
              onClose={toggle}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box style={style}>
                <BirthdayComponent
                  image={image}
                  handleClose={handleClose}
                  toggle={toggle}
                />{' '}
              </Box>
            </Modal>
            <Outlet />
          </Container>
          {/* </Box> */}
        </Box>
      </Box>
    )
  );
}

export default LayoutWrapper;
