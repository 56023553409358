import React, { useCallback, useState } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { useGetIncomeExpenseDataQuery } from 'services/private/dashboard';
import { abbreviateAmount, formatAmount } from 'utilities/helpers';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import CommonMenu from 'containers/common/components/CommonMenu';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';
import { incomeExpense } from 'styles/containers/dashboardStyles';
import { DEFAULT_CURRENCY } from 'utilities/constants';
import useAuthInfo from 'customHooks/useAuthInfo';
import { durationOptions } from '../utilis/data';
import useGetGraphData from '../customHooks/useGetGraphData';

function IncomeAndExpense() {
  const { palette: { primary: { main, secondary } } } = useTheme();
  const { company } = useAuthInfo();
  const [duration, setDuration] = useState('this fiscal year');

  const { data: incomeExpenseData = false, isLoading } = useGetIncomeExpenseDataQuery({ duration, company: company?.id }, { skip: !duration });

  const { graphData } = useGetGraphData(incomeExpenseData, duration, 'incomeExpense');

  const handleMenuItemClicked = useCallback(item => {
    setDuration(item?.value);
  }, []);

  return (
    <Paper className="py-2 px-4 h-100">
      <Box className="pb-2 d-flex justify-content-between align-items-center">
        <Typography variant="h4">Revenue & Cost</Typography>
        <CommonMenu menus={durationOptions} selectedOption={duration} func={handleMenuItemClicked} />
      </Box>
      <Box style={{ paddingBottom: '0px' }}>
        {
          isLoading ? (
            <SectionLoader />
          ) : (
            <>
              <ResponsiveContainer height={220}>
                <BarChart
                  width={700}
                  height={400}
                  data={graphData}
                  margin={{
                    top: 20,
                    right: 40,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis tickFormatter={value => abbreviateAmount(value)} />
                  <Tooltip formatter={value => `${DEFAULT_CURRENCY} ${formatAmount(value)}`} />
                  <ReferenceLine y={0} stroke="#B3B3B3" />
                  <Bar maxBarSize={15} dataKey="Income" fill={main} />
                  <Bar maxBarSize={15} dataKey="Expense" fill={secondary} />
                </BarChart>
              </ResponsiveContainer>
              <Grid spacing={1} className="border-top mt-3" container>
                <Grid item xs={2} className="border-right">
                  <Box className=" position-relative"> <Typography variant="body2" sx={{ paddingLeft: '1rem', '&::before': { ...incomeExpense, background: main } }}>Revenue</Typography></Box>
                  <Box className=" position-relative"> <Typography variant="body2" sx={{ paddingLeft: '1rem', '&::before': { ...incomeExpense, background: secondary } }}>Cost</Typography></Box>
                </Grid>
                <Grid item xs={10}>
                  <Grid className="ml-2" container>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: main }}>Total Revenue</Typography>
                      <Typography variant="body2" className="fw-bold">
                        {`${DEFAULT_CURRENCY} ${formatAmount(incomeExpenseData?.total_income || 0)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: secondary }}>Total Cost</Typography>
                      <Typography variant="body2" className="fw-bold">
                        {`${DEFAULT_CURRENCY} ${formatAmount(incomeExpenseData?.total_expense || 0)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        }
      </Box>
    </Paper>
  );
}

export default IncomeAndExpense;
