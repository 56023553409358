import { privateAPI } from './index';

export const dailyReportsAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getDailyReports: build.query({
      query: id => ({
        url: 'attendance/daily-report/',
        method: 'GET',
        params: {
          attendance__id: id,
        },
      }),
      providesTags: ['GetDailyReports'],
    }),
    addDailyReports: build.mutation({
      query: body => ({
        url: 'attendance/daily-report/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetDailyReports',
        'GetAttendanceCheckIn',
        'GetAttendanceReports',
      ],
    }),
    updateDailyReports: build.mutation({
      query: body => ({
        url: `attendance/daily-report/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetDailyReports',
        'GetAttendanceCheckIn',
        'GetAttendanceReports',
      ],
    }),
    deleteDailyReports: build.mutation({
      query: id => ({
        url: `attendance/daily-report/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetDailyReports',
        'GetAttendanceCheckIn',
        'GetAttendanceReports',
      ],
    }),
    updateSubmitReport: build.mutation({
      query: body => ({
        url: `attendance/report-submission/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetDailyReports',
        'GetAttendanceDates',
        'GetEmployeeAttendanceData',
        'GetAttendanceData',
        'GetExportData',
        'GetAttendanceCheckIn',
        'GetForgetCheckOut',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    // ReportRequest
    getReportRequest: build.query({
      query: params => ({
        url: 'attendance/attendance-request/',
        method: 'GET',
        params
      }),
      providesTags: ['GetReportRequest'],
    }),

    addReportRequest: build.mutation({
      query: body => ({
        url: 'attendance/attendance-request/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetExportData',
        'GetReportRequest',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceReports',
      ],
    }),
    updateReportRequest: build.mutation({
      query: body => ({
        url: `attendance/attendance-request/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetReportRequest',
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetExportData',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceDates',
        'GetAttendanceReports',
      ],
    }),
  }),
});

export const {
  useGetDailyReportsQuery,
  useAddDailyReportsMutation,
  useUpdateDailyReportsMutation,
  useDeleteDailyReportsMutation,
  useUpdateSubmitReportMutation,
  useAddReportRequestMutation,
  useGetReportRequestQuery,
  useUpdateReportRequestMutation,
} = dailyReportsAPI;
