import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormHelperText, Grid, Typography } from '@mui/material';

function FormikTextEditor({
  name,
  onChange,
  onBlur,
  disabled = false,
  classes,
  isRequired,
  isRow,
  label,
  labelMarginBottom,
}) {
  const [{ value: fieldValue }, { touched, error }, { setValue }] = useField(
    name || ''
  );

  // useEffect(() => () => {
  //   ClassicEditor.builtinPlugins.forEach(plugin => editor.plugins.get(plugin.pluginName).destroy());
  //   editor.destroy();
  // }, []);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    if (onChange) onChange(data);
  };

  const handleBlur = (event, editor) => {
    const data = editor.getData();
    setValue(data);
    if (onBlur) onBlur(name, data);
  };

  return (
    <Grid className={classes} spacing={1} container>
      <Grid
        className="d-flex align-items-center"
        item
        xl={isRow ? 3 : 12}
        lg={isRow ? 3 : 12}
        md={isRow ? 4 : 12}
        sm={12}
      >
        {label && (
        <Typography
          className={isRequired ? 'required' : ''}
          variant="body2"
          sx={{ mb: `${labelMarginBottom} !important` }}
        >
          {label}
        </Typography>
        )}
      </Grid>
      <Grid item xl={isRow ? 9 : 12} lg={isRow ? 9 : 12} md={isRow ? 8 : 12} sm={12}>
        <CKEditor
          editor={ClassicEditor}
          data={fieldValue || ''}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
        />
        {touched && error && (
        <FormHelperText className="form__form-group-error">{error}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

FormikTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  label: PropTypes.string,
  labelMarginBottom: PropTypes.string,
};

FormikTextEditor.defaultProps = {
  onChange: null,
  onBlur: null,
  disabled: false,
  classes: '',
  isRequired: false,
  isRow: false,
  label: null,
  labelMarginBottom: '8px',
};

export default FormikTextEditor;
