import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import SectionLoader from '../loaders/SectionLoader';

function TableLoader({ colSpan }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Box sx={{ minHeight: 500 }}>
            <SectionLoader />
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

TableLoader.propTypes = {
  colSpan: PropTypes.number,
};

TableLoader.defaultProps = {
  colSpan: 6,
};

export default TableLoader;
