import { palette } from 'styles/mui/theme';
import { greyColor } from 'styles/mui/themeVariables';

export const toolbarStyling = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const actionBtnsBoxStyling = {
  display: 'flex',
  gap: '10px',
  justifyContent: 'start',
};

export const formCardStyling = {
  marginBottom: '2rem',
};

export const displayAlignCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const forgetPassowrdLink = {
  marginLeft: 'auto',
  marginTop: '-10px',
  color: palette.primary.main,
};

export const fileInputBox = {
  width: '100%',
  backgroundColor: '#e5e5e5',
  textAlign: 'center',
  borderRadius: '10px'
};

export const inputFileFigure = {
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  backgroundColor: '#70bbfd',
  display: 'block',
  padding: '20px',
  margin: '0 auto 10px',
};

export const commonStyles = {
  menu: baseStyles => ({
    ...baseStyles,
    zIndex: 10,
  }),
  option: (styles, { isDisabled }) => ({
    cursor: isDisabled ? 'not-allowed' : 'default',
    color: isDisabled ? '#aaa !important' : '#000',
  }),
};

export const groupedStyles = {
  groupHeading: provided => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#000000',
  }),
  option: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: '13px',
    cursor: isDisabled ? 'not-allowed' : 'default',
    color: isDisabled ? '#aaa !important' : '#000',
  }),
};

export const styledInputFile = {
  width: '100%',
  height: '200px',
  background: greyColor,
  borderRadius: '15px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
