import { useTheme } from '@mui/material';
import { useMemo } from 'react';

const useGetThemeColor = () => {
  const { palette } = useTheme();
  const primary = useMemo(() => palette.primary.main, [palette]);

  return primary;
};

export default useGetThemeColor;
