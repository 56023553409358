import round from 'lodash/round';
import { useMemo } from 'react';

import { formatAmount } from 'utilities/helpers';

function useGetTotalsData(apiData, type) {
  const currentAmount = useMemo(
    () => (apiData
      ? apiData?.current?.reduce((acc, val) => {
        const appendMinus = val.type === 'Credit Note' || val.type === 'Excess Payment' || (val.type === 'Account Opening Balance' && val.is_credit);

        if (appendMinus) {
          return acc - round(val.amount_due_bcy, 2);
        }
        return acc + round(val.amount_due_bcy, 2);
      }, 0)
      : 0),
    [apiData, type]
  );

  const date1To15OverdueTotal = useMemo(
    () => (apiData
      ? apiData?.date_1_15?.reduce((acc, val) => {
        const appendMinus = val.type === 'Credit Note' || val.type === 'Excess Payment' || (val.type === 'Account Opening Balance' && val.is_credit);

        if (appendMinus) {
          return acc - round(val.amount_due_bcy, 2);
        }
        return acc + round(val.amount_due_bcy, 2);
      }, 0)
      : 0),
    [apiData, type]
  );

  const date16To30OverdueTotal = useMemo(
    () => (apiData
      ? apiData?.date_16_30?.reduce((acc, val) => {
        const appendMinus = val.type === 'Credit Note' || val.type === 'Excess Payment' || (val.type === 'Account Opening Balance' && val.is_credit);

        if (appendMinus) {
          return acc - round(val.amount_due_bcy, 2);
        }
        return acc + round(val.amount_due_bcy, 2);
      }, 0)
      : 0),
    [apiData, type]
  );

  const date31To45OverdueTotal = useMemo(
    () => (apiData
      ? apiData?.date_31_45?.reduce((acc, val) => {
        const appendMinus = val.type === 'Credit Note' || val.type === 'Excess Payment' || (val.type === 'Account Opening Balance' && val.is_credit);

        if (appendMinus) {
          return acc - round(val.amount_due_bcy, 2);
        }
        return acc + round(val.amount_due_bcy, 2);
      }, 0)
      : 0),
    [apiData, type]
  );

  const dateGreaterThan45Total = useMemo(
    () => (apiData
      ? apiData?.date_gt_45?.reduce((acc, val) => {
        const appendMinus = val.type === 'Credit Note' || val.type === 'Excess Payment' || (val.type === 'Account Opening Balance' && val.is_credit);

        if (appendMinus) {
          return acc - round(val.amount_due_bcy, 2);
        }
        return acc + round(val.amount_due_bcy, 2);
      }, 0)
      : 0),
    [apiData, type]
  );

  const overdueAmount = useMemo(
    () => date1To15OverdueTotal +
      date16To30OverdueTotal +
      date31To45OverdueTotal +
      dateGreaterThan45Total,
    [apiData]
  );

  const totalUnpaids = useMemo(() => currentAmount + overdueAmount, [apiData, type]);

  const popupData = useMemo(
    () => [
      {
        interval: '1_15',
        duration: '1-15 days',
        amount: formatAmount(date1To15OverdueTotal),
      },
      {
        interval: '16_30',
        duration: '16-30 days',
        amount: formatAmount(date16To30OverdueTotal),
      },
      {
        interval: '31_45',
        duration: '31-45 days',
        amount: formatAmount(date31To45OverdueTotal),
      },
      {
        interval: 'gt_45',
        duration: 'Above 45 days',
        amount: formatAmount(dateGreaterThan45Total),
      },
    ],
    [apiData, type]
  );

  return {
    dateGreaterThan45Total,
    date31To45OverdueTotal,
    date16To30OverdueTotal,
    date1To15OverdueTotal,
    popupData,
    totalUnpaids,
    overdueAmount,
  };
}

export default useGetTotalsData;
