import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import Calendar from 'containers/common/components/Calendar';
import HolildayDetail from 'containers/common/components/HolidayDetail';
import { useLazyGetInternalMeetingsQuery } from 'services/private/internalMeeting';
import { useLazyGetCompanyActivitiesQuery } from 'services/private/companyActivities';
import { useLazyGetCompanyHolidaysQuery } from 'services/private/companyHolidays';
import CommonModal from 'containers/common/components/CommonModal';
import 'styles/common/calendar.scss';
import { Box } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';

const heading = ['Meeting Detail', 'Activity Detail', 'Holiday Detail'];

function DashBoardCalendar() {
  const [modal, setModal] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const { company } = useAuthInfo();

  const [getInternalMeetings, { data: meetings = [] }] = useLazyGetInternalMeetingsQuery();
  const [getCompanyActivities, { data: activities = [], }] = useLazyGetCompanyActivitiesQuery();
  const [getCompanyHolidays, { data: holidays = [], }] = useLazyGetCompanyHolidaysQuery();

  const toggle = () => setModal(!modal);

  const getMonthEvents = async date => {
    const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');

    const params = {
      from_date: startOfMonth,
      to_date: endOfMonth,
      company: company?.id
    };
    await getInternalMeetings(params);
    await getCompanyActivities(params);
    await getCompanyHolidays(params);
  };

  useEffect(() => {
    if (company) {
      getMonthEvents();
    }
  }, [company]);

  const modifiedMeetings = useMemo(() => (meetings?.user_own_meetings
    ? meetings?.user_own_meetings.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      start: new Date(`${item.meeting_date} ${item.from_time}`),
      end: new Date(`${item.meeting_date} ${item.to_time}`),
      priority: 'high',
      event_index: 0,
    }))
    : []), [meetings]);
  const modifiedParticipateMeetings = useMemo(() => (meetings?.user_participated_meetings
    ? meetings?.user_participated_meetings?.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      start: new Date(`${item.meeting_date} ${item.from_time}`),
      end: new Date(`${item.meeting_date} ${item.to_time}`),
      priority: 'high',
      event_index: 0,
    }))
    : []), [meetings]);
  const modifiedActivities = ((activities?.length > 0 && activities !== null)
    ? activities.map(activity => ({
      id: activity.id,
      title: activity.title,
      description: activity.description,
      start: new Date(activity.start_date),
      end: new Date(activity.end_date),
      start_time: activity.start_time,
      end_time: activity.end_time,
      allDay: true,
      priority: 'family',
      event_index: 1,
    }))
    : []);

  const modifiedHolidays = (holidays?.length > 0 && holidays !== null)
    ?
    holidays.map(holiday => ({
      id: holiday.id,
      title: holiday.title,
      description: holiday.description,
      start: new Date(holiday.from_date),
      end: new Date(holiday.to_date),
      allDay: true,
      event_index: 2,
      priority: 'non',
    })) : [];

  const handleSelectEvent = event => {
    setSelectedHoliday(event);
    setModal(true);
  };

  return (
    <>
      <CommonModal title={heading[selectedHoliday?.event_index]} isOpen={modal} toggle={toggle}>
        <Box sx={{ width: '450px', overflowX: 'hidden' }} className="pb-2">
          <HolildayDetail selectedHoliday={selectedHoliday} />
        </Box>
      </CommonModal>

      <Calendar
        // small
        events={[
          ...modifiedActivities,
          ...modifiedParticipateMeetings,
          ...modifiedMeetings,
          ...modifiedHolidays,
        ]}
        onSelectEvent={handleSelectEvent}
        onNavigate={getMonthEvents}
      />
    </>
  );
}

export default DashBoardCalendar;
