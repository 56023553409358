import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthInfo from 'customHooks/useAuthInfo';

function OperationsRolesRoute() {
  const { isOperationsStaff } = useAuthInfo();

  if (isOperationsStaff) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default OperationsRolesRoute;
