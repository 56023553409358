import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Remove } from '@mui/icons-material';

// COMPONENTS & UTILS
import FormikModernTimePicker from 'shared/form/FormikTimePicker';
import SubmitBtn from 'shared/form/SubmitBtn';
import { FormikField } from 'shared/form';
import moment from 'moment';
import {
  editBreakFormInitialSchema,
  editBreakFormInitialValue,
} from '../../utilities/formUtils';

function EditBreakForm({
  toggle,
  breakData,
  breakForget,
  updateBreakTimeManually,
}) {
  const [initValues, setInitValues] = useState(editBreakFormInitialValue);

  useEffect(() => {
    setInitValues({
      start_break_time: moment(breakData.start_break_time, 'HH:mm:ss').format('HH:mm:ss'),
      end_break_time: breakData.end_break_time || '',
      break_reason: breakData.break_reason || '',
    });
  }, [breakData]);
  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={editBreakFormInitialSchema}
      onSubmit={async (values, { resetForm }) => {
        if (!breakForget) {
          const response = await updateBreakTimeManually({
            ...values,
            id: breakData?.id,
          });
          if (!response.error) {
            toggle();
            resetForm();
          }
        } else {
          const response = await updateBreakTimeManually({
            end_break_time: values?.end_break_time,
            id: breakData?.id,
          });
          if (!response.error) {
            toggle();
            resetForm();
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="d-flex align-items-start flex-column gap-3">
          <Grid container spacing={2}>
            <Grid container spacing={1} item>
              <Grid item xs={12} md={3}>
                <Typography className="required" variant="label">
                  Time Interval
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <Stack
                  direction="row"
                  className="justify-content-between align-items-center w-100"
                  columnGap={1}
                >
                  <FormikModernTimePicker
                    name="start_break_time"
                    disabled={breakForget}
                    disablePast
                  />

                  <Remove />

                  <FormikModernTimePicker name="end_break_time" disablePast />
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={1} item>
              <Grid item xs={12} md={3}>
                <Typography className="required" variant="label">
                  Reason
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <FormikField
                  name="break_reason"
                  textArea
                />
              </Grid>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1}>
            <SubmitBtn label="Update" isLoading={isSubmitting} />

            <Button variant="mutedColor" onClick={toggle}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

EditBreakForm.defaultProps = {
  toggle: () => null,
  updateBreakTimeManually: () => {},
  breakForget: false,
  breakData: {},
};

EditBreakForm.propTypes = {
  toggle: propTypes.func,
  updateBreakTimeManually: propTypes.func,
  breakForget: propTypes.bool,
  breakData: propTypes.object,
};

export default EditBreakForm;
