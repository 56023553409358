/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowCircleRightOutlined, ArrowCircleLeftOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import Slider from 'react-slick';
import { useGetLatestAnnouncementsQuery } from 'services/private/announcements';
import useAuthInfo from 'customHooks/useAuthInfo';
import { Box, Grid, Typography, useTheme, } from '@mui/material';
import { COMPANY_DETAILS } from 'utilities/constants';

function AnnoucmentSlider() {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const { company } = useAuthInfo();
  const sliderRef = useRef(null);

  const [active, setActiveSlide] = useState(1);
  const [isRecordExist, setIsRecordExist] = useState(false);
  const { data: announcements } = useGetLatestAnnouncementsQuery({ company: company?.id });

  const modifiedAnnoucements = announcements?.flatMap(obj => [obj, {}]);

  const { user } = useAuthInfo();

  const handleAddCount = () => (active === announcements?.length
    ? () => {}
    : setActiveSlide(prevState => prevState + 1));
  const handleRemoveCount = () => setActiveSlide(prevState => prevState - 1);
  const handleChangeRecordState = () => setIsRecordExist(prevState => !prevState);

  function CustomPrevArrow(props) {
    const { className, currentSlide } = props;
    const disablebtn = currentSlide === 0;

    const handleClick = () => {
      if (currentSlide % 2 === 0) {
        handleRemoveCount();
      }
      handleChangeRecordState();
      sliderRef.current.slickGoTo(currentSlide - 2);
    };

    return (
      <div
        role="button"
        tabIndex="0"
        className={className}
        style={{
          pointerEvents: disablebtn ? 'none' : '',
        }}
        onClick={handleClick}
      >
        <ArrowCircleLeftOutlined />
      </div>
    );
  }
  function CustomNextArrow(props) {
    const { className, currentSlide } = props;

    const handleClick = () => {
      if (currentSlide % 2 === 0) {
        handleAddCount();
      }
      handleChangeRecordState();
      sliderRef.current.slickGoTo(currentSlide + 2);
    };

    return (
      <div
        role="button"
        tabIndex="0"
        className={className}
        // style={{ pointerEvents: disablebtn ? 'none' : '' }}
        onClick={handleClick}
      >
        <ArrowCircleRightOutlined />
      </div>
    );
  }

  const settings = {
    infinite: true,
    autoplay: true,
    fade: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    beforeChange: (current, next) => {
      const isNextZero = next === 0;
      if (!isNextZero && current < next && current % 2 !== 0) {
        handleAddCount();
      }
      if (isNextZero) {
        setActiveSlide(1);
      }

      if (current % 2 !== 0) {
        handleChangeRecordState();
      }
      if (current % 2 === 0) {
        handleChangeRecordState();
      }
    },
  };

  return (
    <Box>
      {announcements?.length > 0 ? (
        <Grid className="border rounded-3 my-1 d-flex align-items-center" sx={{ border: `1px solid ${primary}`, background: 'white' }} container>
          <Grid item md={2} lg={2} className="heading p-2" sx={{ background: primary, textAlign: 'center', color: 'white', borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px' }}>
            <Typography variant="body2">Announcements</Typography>
          </Grid>

          <Grid item md={9} lg={9} className="annoucement-carousel white-bg p-1 py-2 px-5 rounded-right">

            <Slider ref={sliderRef} {...settings}>
              {modifiedAnnoucements.map(announcement => (
                <a
                  className={`hover-black ${
                    !isEmpty(announcement) &&
                    !announcement.viewed_by.includes(user.id)
                      ? 'bold-text'
                      : ''
                  } `}
                  href={`/hr/announcements/${announcement.id}/detail`}
                  key={announcement.id}
                  style={{ color: 'black' }}
                >
                  {announcement.subject}
                </a>
              ))}
            </Slider>

          </Grid>
          <Grid item md={1} lg={1} className="heading rounded-left pr-4 " sx={{ textAlign: 'left' }}>
            <Typography variant="body2">{`${active} of ${announcements.length}`}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h3" className="w-100">

          Welcome to <span style={{ color: primary, fontStyle: 'italic', fontWeight: 'bold' }}>{company?.company_name?.toUpperCase()}</span>

        </Typography>
      )}
    </Box>
  );
}

export default AnnoucmentSlider;
