import { useTheme } from '@mui/material';

const useGetChartColorsArray = () => {
  const { palette } = useTheme();

  const { chartsPlatte } = palette;
  return Object.values(chartsPlatte);
};

export default useGetChartColorsArray;
