import React from 'react';

import { Grid, } from '@mui/material';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
// import AttendanceBar from 'containers/pages/attendance/components/AttendanceBar';
import TotalReceiveables from './components/TotalReceiveables';
// import AnnouncementSlider from '../../Common/Announcements';
import TotalPayables from './components/TotalPayables';
import TopExpenses from './components/TopExpenses';
// import DashBoardCalendar from '../DashboardCalender';
import IncomeAndExpense from './components/IncomeAndExpense';

function AnalyticsTab() {
  return (
    <>
      <MetaTagsHelmet moduleName="Dashboard" />

      <Grid spacing={2} className="mb-3" container>
        <Grid item md={12} lg={6} xl={6}>
          <TotalReceiveables />
        </Grid>
        <Grid item md={12} lg={6} xl={6}>
          <TotalPayables />
        </Grid>
        <Grid item md={12} lg={7} xl={8}>
          <IncomeAndExpense />
        </Grid>
        <Grid item md={12} lg={5} xl={4}>
          <TopExpenses />
        </Grid>
      </Grid>
    </>
  );
}

export default AnalyticsTab;
