/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

function RadioButtons({ name, options, onChange, ...restProps }) {
  return (
    <Field name={name}>
      {({
        field: {
          value: fieldValue,
          onChange: onFieldChange,
          ...restFieldProps
        },
      }) => options.map(option => (
        <React.Fragment key={option.label}>
          <label className="radio-btn">
            <input
              className="radio-btn__radio"
              type="radio"
              id={option.value}
              {...restFieldProps}
              {...restProps}
              value={option.value}
              checked={fieldValue === option.value}
              onChange={e => {
                onFieldChange(e);
                onChange(e.target.value);
              }}
            />
            <span className="ml-1 mr-2 mb-2" style={{ fontSize: '12px' }}>{option.label}</span>
          </label>
        </React.Fragment>
      ))}
    </Field>
  );
}

RadioButtons.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

RadioButtons.defaultProps = {
  name: '',
  options: [],
  onChange: () => { },
};

export default RadioButtons;
